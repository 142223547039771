@import "../../sass/variables";
@import "../../sass/mixins";

.introBlk {
  border-radius: 8px;
  text-align: left;
  // padding: 10px !important;
  .secMainTitleImg {
    float: left;
    font-size: 25px;
    margin-top: 10px;
    color: var(--tal_primary);
    margin-right: 10px;
  }
  .secMainTitle {
    font-weight: 900;
    font-size: 20px;
    line-height: 40px;
  }
  .secTitle {
    font-weight: 600;
    margin: 25px 0px 5px 0px;
    display: inline-block;
  }
  .textBlk {
    background: #fff;
    display: inline-block;
    width: 100%;

    p {
      margin-bottom: 0;
    }
    .public-social-links {
      h4 {
        font: 600 14px $font-regular;
      }
      p {
        color: var(--tal_primary);
        line-height: 15px;
        font: 600 12px $font-regular !important;
      }
    }
    img {
      border-radius: 8px;
      width: 100%;
    }
    p {
      font-weight: 400;
      &.secTitle {
        font-weight: 600;
        color: #424242;
        margin: 25px 0px 5px 0px;
        display: inline-block;
      }
    }
    .userSocial {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        margin: 5px 0px;
        #facebook {
          color: #2196f3;
        }
        #insta {
          color: #e91e63;
        }
        #youtube {
          color: #f44336;
        }
        a {
          margin-left: 10px;
        }
      }
    }
    .artistIco {
      color: #673ab7;
      float: left;
      margin-right: 5px;
    }
  }
  .mediaBlk {
    display: inline-block;
    width: 100%;
    margin: 10px 0;
    margin-bottom: 0 !important;
    background: #fff;

    .public-view-all {
      cursor: pointer;
      display: inline-block;
      width: 20%;
      font-weight: 500;
      font-size: 12px;

      display: inline-block;
      text-align: end;
      text-decoration: underline;
    }
    .MuiGrid-container {
      height: auto !important;
      div {
        height: auto !important;
      }
    }
    .MuiTypography-body1,
    .image-title {
      display: none;
    }
    img {
      width: 100%;
    }
    p {
      margin-bottom: 10px;
      &:nth-child(1) {
        // float: left;
        font-weight: 700;
      }
      &:nth-child(2) {
        float: right;
        font-weight: 400;
        font-size: 15px;
      }
    }
    .mediaHeadDivide {
      float: left;
      margin-bottom: 10px;
      width: 100%;
    }
    .photosList {
      margin: 0px;
      padding: 0px;
      li {
        width: 46%;
        margin: 2%;
        list-style: none;
        display: inline-block;
        img {
          border-radius: 5px;
          box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
          width: 100%;
        }
      }
    }
  }
}
.textBlk {
  p {
    font: 400 12px $font-regular;
    svg {
      font-size: 24px;
    }
  }
}
.video100 {
  div {
    div {
      width: 100% !important;
    }
  }
}
