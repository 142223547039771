@import "../../sass/variables";
@import "../../sass/mixins";

.home2-container {
  .search_home {
    padding: 8px;
    margin-bottom: 8px;
    display: flex;

    padding: 16px;
  }
  .search_btn {
    color: #898989;
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    padding: 12px;
    box-shadow: 0 0 10px #e9e9e9;
    :hover {
      background-color: white !important;
      width: 20px;
      height: 20px;
      color: rgb(205, 38, 39);
      border-radius: 50px;

      border: none;
    }
  }
  .myfeed {
    p {
      background: #f6f7fb;
      width: 58px;
      z-index: 1;
      position: relative;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
    }
    hr {
      background: rgb(246, 247, 251);
      position: absolute;
      top: -5px;
      width: 100%;
    }
  }

  .Mui-selected {
    color: red !important;
  }

  .details-child lable {
    display: block !important;
  }
  // .filter-requests {
  //   display: flex;
  //   flex-direction: column;
  //   padding: 5px 10px 0;
  //   h6 {
  //     border-bottom: 1px solid rgb(246, 247, 251);
  //     margin: 0;
  //     padding-bottom: 8px;
  //     font-weight: 400;
  //   }
  // }
  .tabs-chips {
    padding: 12px 5px 5px 5px;
    margin-bottom: 10px;
    font-weight: bold;
    position: relative;
    z-index: 1;

    .chip-main {
      display: flex;
      justify-content: space-between;
      border: 1px solid rgb(246, 247, 251);
      min-height: 50px;

      .fild {
        position: absolute;
        left: 15px;
        top: 2px;
        background: white;
        padding: 2px 5px 0 5px;
        font-weight: 400;
        font-size: 12px;
      }
      h5 {
        position: absolute;
        top: -17px;
        font-size: 14px;
        font-weight: 400;
        background-color: #ffffff;
        border-radius: 4px;
        padding: 1px 8px 0 8px;
        left: 6px;
        margin: 8px 0 0 0;
      }

      .MuiChip-root.makeStyles-chip-26.MuiChip-deletable:hover {
        background-color: rgb(205, 38, 39);
        color: white;
      }
      .MuiChip-root.makeStyles-chip-26.MuiChip-deletable:hover
        .MuiChip-deleteIcon {
        color: white;
        font-size: 16px;
      }
    }
    h5 {
      margin: 10px 0;
      white-space: nowrap;
    }

    // @media screen and (min-width: 600px) {
    .chip-box-desktop {
      display: flex;
      justify-content: space-between;
    }
    // .chip-box-mobile {
    //   display: none;
    // }
    // }
    // @media screen and (max-width: 599px) {
    //   .chip-box-mobile {
    //     display: flex;
    //     justify-content: space-between;
    //   }
    //   .chip-box-desktop {
    //     display: none;
    //   }
    // }

    .filter-bg {
      position: relative;
      right: 4px;
      background: #f6f7fb;
      border-radius: 5px;
      top: 4px;
      height: 40px;
      display: flex;
      width: 40px;

      .filter-one {
        button {
          padding: 20px;
          border-radius: 0;
          background-image: url("/images/filter.svg");
          background-size: 20px;
          background-repeat: no-repeat;
          background-position: center center;
        }
        button:hover {
          background-color: white !important;
          border-radius: 4px;
          box-shadow: 0 0 9px #e8eaf5;
          background-image: url("/images/filter-hover.svg");
          background-repeat: no-repeat;
        }
      }
      // .filter-two {
      //   button {
      //     background-size: 22px;
      //     border-radius: 0;
      //     background-repeat: no-repeat;
      //     background-position: center center;
      //   }
      //   button:hover {
      //     background-color: white !important;
      //     border-radius: 0 4px 4px 0;
      //     box-shadow: 0 0 9px #e8eaf5;
      //     background-repeat: no-repeat;
      //     color: rgb(205, 38, 39);
      //   }
      // }
    }

    .MuiChip-label {
      border-radius: 4px;
    }

    .paper-tab {
      position: relative;
      padding: 0;

      h6 {
        font-weight: 400;
      }

      ul {
        display: flex;
        li:nth-child(1) {
          border-right: 2px solid #efefef;
          color: rgb(205, 38, 39);
        }

        .app-bar {
          .MuiTab-textColorInherit.Mui-selected {
            color: rgb(205, 38, 39) !important;
          }
        }
      }

      .check-count {
        padding: 0px 17px 1px;
        font-weight: normal;
        p {
          color: gray;
        }
      }
    }
  }

  .hover-btn {
    button {
      background-color: transparent;
      box-shadow: none;
      padding: 12px !important;
    }
    button:hover {
      background-color: #f6f7fb;
    }
  }

  .post-actions-left {
    padding: 0 5px;
  }
  .not-logged-text {
    padding: 10px 0 20px 5px;
  }

  .container-right .MuiCardContentRoot {
    padding: 0 !important;
  }

  @media screen and (max-width: 960px) {
    .comments-container {
      padding: 0 20px;
    }
  }

  .hamburger-icon {
    display: none;
    border-right: 2px solid #ccc;
  }

  .container-left {
    border-radius: 3px;
    border-left: none;
    &.profile-left {
      background: #f5f5f5;
      border: none;
      border-radius: 5px;
    }
    .no-pad {
      padding: 0;
    }
    .user-details-cont {
      text-align: left;
      .user-details {
        .user-name {
          text-align: left;
          background-size: 20%;
          height: 70px;
          .user-profile-image {
            float: left;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 10px;
          }
          > span {
            margin-top: 0;
            display: inline-block;
            font-weight: 900;
            > span {
              margin: 5px 10px;
              display: block;
              font-weight: 500;
              color: #666;
              cursor: pointer;
              a {
                color: #9e9e9e;
                text-decoration: none;
                font-size: 14px;
                font-weight: normal;
                &:hover {
                  color: #cd2527;
                }
              }
            }
          }
        }
        .position {
          width: 100%;
          line-height: 30px;
          text-align: center;
          margin: 0 auto;
          font-size: 15px;
        }
      }
      .user-ratings-blk {
        margin-bottom: 0px;
        margin-top: 20px;
        text-align: center;
      }
    }
    .saved-req-blk {
      text-align: left;

      .saved-req-link {
        background-image: url("https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/material3/ic_turned_in_48px.svg");
        background-repeat: no-repeat;
        height: 48px;
        > a {
          color: #424242;
          text-decoration: none;
          padding-left: 55px;
          display: inline-block;
          margin-top: 10px;
          font-size: 17px;
          font-weight: bold;
        }
      }
    }
    .personal-links-cont {
      font-weight: 400;
      ul {
        padding: 0px;
        margin: 0px;
        li {
          display: flex;
          width: 100%;
          text-align: left;
          padding: 0px 0px;
          margin: 10px 0px;
          justify-content: space-between;

          span:nth-child(2) {
            float: left;
            color: #2196f3;
          }
        }
      }
    }
    .user-navigation-blk {
      border-left: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background: #fff;
      .user-nav-left {
        padding: 30px 15px 30px 15px;
        width: 100%;
      }
    }
  }
  .new-request-paper,
  .categories-paper {
    display: none;
    background: #f5f5f5;
    padding: 0;
  }
  .container-center {
    position: relative;
    .postCardPaper {
      margin: 0px 0px 25px 0px;

      .MuiPaper-elevation1 {
        overflow: visible;
      }
    }

    .new-req-blk {
      display: inline-block;

      cursor: pointer;
      width: 100%;
      .new-req-header {
        background: rgba(224, 224, 224, 0.5);
        border: 1px solid #e0e0e0;
        text-align: left;
        color: #424242;
        font-weight: bold;
        font-size: 17px;
        padding: 10px 20px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        margin-bottom: 0px;
        border-bottom: none;
      }
      .new-req-form {
        display: inline-block;
        width: 100%;
        border: 1px solid #e0e0e0;
        text-align: left;
        ul {
          margin: 0px;
          padding: 0px;

          li {
            float: left;
            list-style: none;
            .what-new {
              background-image: url("https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/default/user_male_circle.svg");
              background-repeat: no-repeat;
              padding: 10px;
              padding-left: 55px;
              font-size: 20px;
              background-size: 12%;
              padding-top: 0px;
              background-position: left top;
            }
          }
          li.new-req-image {
            width: 16%;
            padding: 25px;
            border-left: 1px solid #ccc;
          }
          li.new-req-video {
            width: 16%;
            padding: 25px;
            border-left: 1px solid #ccc;
          }
          li.new-req-doc {
            width: 16%;
            padding: 25px;
            border-left: 1px solid #ccc;
          }
          li:nth-child(1) {
            padding-top: 30px;
            padding-left: 20px;
            width: 52%;
          }
        }
      }
    }
    .cat-list-blk {
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 7px;
      .header {
        float: left;
        width: 94%;
        padding: 0px 30px;
        p {
          margin: 4px 0;
          padding: 3px 0;
        }
        p:nth-child(1) {
          float: left;
        }
        p:nth-child(2) {
          float: right;
        }
      }
      ul {
        float: left;
        margin: 0px;
        padding: 0px;
        li {
          display: inline-block;
          width: 23%;
          position: relative;
          margin-right: 10px;
          .close {
            position: absolute;
            right: 15px;
            top: 0px;
            cursor: pointer;
          }
          .close::after {
            content: "X";
            border: 1px solid #ccc;
            box-shadow: 0px 0px 1px #000;
            border-radius: 50%;
            padding: 2px 7px;
            font-size: 10px;
          }
          img {
            width: 75%;
            height: 70px;
            box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
            border-radius: 7px;
          }
          p {
            margin: 3px 0;
          }
        }
      }
    }
    .postCard {
      text-align: left;

      // @media screen and (max-width: 600px) {
      //   padding: 0 10px;
      // }
      .user-profile-img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      .postBy {
        padding: 8px 16px;
        padding-top: 15px;
        text-transform: none;
        margin-bottom: -5px;
        &::first-letter {
          text-transform: uppercase;
        }
        .MuiTypography-body2 {
          font-weight: bold;
        }
        .MuiCardHeader-subheader {
          font-weight: normal;
        }
        .MuiAvatar-circle {
          background: #c2c2c2;
          box-shadow: 0 0 0px 4px #e9e9e9;
          width: 47px;
          height: 47px;
        }
      }
      .postTitle {
        padding: 5px 5px;
        margin-top: 8px;
        .postTitle-text {
          line-height: 20px;
          display: inline-block;
          font-weight: 600;
          font-size: 15px;
          padding: 5px 0;
          // white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: -19px;
          margin-top: 12px;
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      .postTitle,
      .postSubTitle {
        margin-bottom: 0px;
        font-weight: bold;
        text-transform: capitalize;
        padding: 0 15px;
        line-height: 1;
        margin-top: -1px;
      }
      .postSubTitle {
        font-weight: normal;
        font: 400 12px $font-regular;
        padding: 10px 0 0;
      }
      .postImg {
        background-color: #e3e3e3;
        max-width: 100%;
        min-height: 150px;
        /* max-height: 600px;*/
        display: block;
        place-content: center;
        figure {
          margin: 0;
          padding: 0;
          text-align: center;
          img {
            width: 100%;
            display: block;
            /*max-height: 600px;*/
          }
        }
      }
      .post-card-content {
        padding: 15px 16px 0;

        .blood-space {
          text-transform: capitalize;

          span {
            display: flex;
            justify-content: space-between;
            margin: 5px 0;
            @media screen and (max-width: 600px) {
              flex-direction: column;
            }
          }
          svg {
            background: white;
            border-radius: 50px;
            padding: 6px;
            font-size: 25px;
            margin-right: 5px;
            // box-shadow: 0 0 7px #e8eaf5;
            background: rgb(246, 247, 251);
            width: 1.2em;
            height: 1.2em;
          }
        }
      }
      .prgBar {
        border-radius: 10px;
        height: 8px;
        margin: 10px 0;
      }
      .postDividerIn {
        margin: 10px 0px 0px;
      }
      .remaining-amt {
        text-align: start;
        color: #000;
        font-size: 14px;
        margin-bottom: 0;
      }
      .postDesc {
        // color: #333;
        // padding: 0 15px 15px;
        font-size: 13px;
        position: relative;
        font-weight: 400;
        line-height: 18.5px;
        margin-right: 75px;
        &::first-letter {
          text-transform: uppercase;
        }
      }
      .post-view-more {
        display: none;
        float: right;
        color: rgb(64, 64, 182);
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 0;
        background: #fff;
        // padding-left: 20px;
        &::before {
          content: "... \00a0 \00a0 \00a0 ";
          color: #000;
          text-decoration: none;
        }
      }
      .card-actions {
        padding: 5px;
      }
      .post-actions-left {
        float: left;
        min-width: 100%;
        button {
          padding: 5px 6px;
          background-color: transparent;
          box-shadow: none;
          // &:hover{
          //   background-color: rgb(246, 247, 251);
          //   box-shadow: 2px 2px 1px #f6f6f6;
          // }
        }
        .action-text {
          font: 500 13px Roboto;
          margin-left: 2px;
          color: #555;
        }
      }

      .post-action-right {
        text-align: right;
        width: 160px;
        margin-left: 169px;
        // margin-top: 20px;
        .donateNowBtn {
          @include submitButton;
          font-size: 11px !important;
          color: #fff !important;
          font-weight: 600;
          width: 77%;
          height: 37px;
          margin: 0;
        }
      }
    }
  }
  .container-right {
    .promotion.drop-box:nth-child(2) {
      margin-top: 20px;
    }
    .promotion {
      img {
        width: 100%;
        border-radius: 3px;
      }
    }
    .orgListBlk {
      margin-top: 25px;
      .orgListInner {
        display: inline-block;
        text-align: left;
        .orgList-title {
          margin: 0px 0px 20px 0px;
          font-weight: bold;
          background-image: url("https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/material3/ic_location_city_48px.svg");
          background-repeat: no-repeat;
          height: 48px;
          background-position: left top;
          padding: 5px 0px 0px 40px;
          background-size: 10%;
          font-size: 17px;
          margin-bottom: 0px;
        }
        ul {
          padding: 0px;
          margin: 0px;
          li {
            display: inline-block;
            width: auto;
            text-align: left;
            padding: 5px;
            img {
              height: 59px;
            }
            li:last-child img {
              height: 75px;
            }
          }
        }
      }
    }
    .latestNewsBlk {
      margin-top: 20px;

      .newsList {
        text-align: left;

        .newlist-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          background-repeat: no-repeat;
          background-position: left top;
          background-size: 100%;
          background-image: url("https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/material3/ic_subject_48px.svg");
        }
        .newsList-title {
          color: #424242;
          font-weight: 500;
          margin-top: -3px;
          font-size: 16px;
          margin-bottom: 10px;
        }
        ul {
          margin: 0px;
          padding: 0px;
          li {
            padding: 0px 0px 10px 0px;
            margin: 0px 0px 0px 20px;
            font: 400 12px $font-regular;
            color: #424242;
            font-size: 13px;
            line-height: 20px;
            list-style: none;
          }
          li::before {
            content: "•";
            color: #2196f3;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
        .newsListActions {
          float: right;
        }
      }
    }
    .topReqListBlk {
      padding: 16px 0;
      .topReqList {
        text-align: left;
        .topReqListTitle {
          background-image: url("https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/material3/ic_beenhere_48px.svg");
          background-repeat: no-repeat;

          font-size: 14px;
          font-weight: 800;
          color: #424242;
          padding: 0px 0px 7px 40px;
          background-size: 10%;
        }
      }
      ul {
        margin: 0px;
        padding: 0px;
        li {
          list-style: none;
          padding: 20px 0px 20px;
          border-bottom: 1px solid #e0e0e0;
          img {
            height: 40px;
            width: 70px;
            float: left;
            padding-right: 10px;
          }
          span {
            color: #0067ba;
            font-size: 12px;
          }
        }
      }
    }
    .home-footer {
      margin: 20px 0;
      padding: 15px;
      border-top: 1px solid #000;
      text-align: center;

      p {
        font: 500 12px $font-regular;
        a {
          color: #000;
        }
      }
    }
  }
  .profile-banner-container {
    display: inline-block;
    margin: 0px 0px 35px 0px;
    width: 100%;
    .profile-banner-bg {
      background-image: url("/images/celeb-profile-bg.jpeg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: inline-block;
      width: 100%;
      min-height: 372px;
      border-radius: 8px;
      .updateBannerBlk {
        display: inline-block;
        width: 100%;
        text-align: right;
        .updateIcon {
          color: #fff;
          cursor: pointer;
          font-size: 50px;
          opacity: 0.6;
        }
      }
      .profile-banner-left {
        position: relative;
        width: 50%;
        .profileInnerBlk {
          position: absolute;
          bottom: -372px;
          width: 100%;
          left: 5%;
          .profileImg {
            float: left;
            width: 30%;
            border-radius: 50%;
            border: 5px solid #fff;
            box-shadow: 0px 0px 5px #000;
          }
          .userName {
            color: #fff;
            position: absolute;
            bottom: 85px;
            left: 35%;
            font-size: 40px;
            font-weight: 900;
          }
        }
      }
      .profile-banner-right {
        float: right;
        width: 19%;
        text-align: left;
        position: relative;
        top: 170px;
        p {
          color: #fff;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 5px;
        }
        .profileDonateBtn {
          background-color: #f44336;
          width: 96%;
          height: 50px;
          font-size: 20px;
          font-weight: 600;
          text-transform: capitalize;
        }
      }
    }
  }
  .profile-tabs-container {
    margin-bottom: 30px;
    .left {
      max-width: 22%;
      flex: 0 0 22%;
      padding-right: 0px;
    }
    .right {
      max-width: 78%;
      flex: 0 0 77%;
      padding-left: 0px;
      padding-right: 10px;
      .pageTabsBlk {
        border-bottom: 1px solid #ccc;
        display: inline-block;
        width: 100%;
        .tabs {
          float: left;
          margin: 0px;
          padding: 0px;
          width: 40%;
          li {
            color: #757575;
            float: left;
            font-weight: 600;
            list-style: none;
            padding: 15px 0px;
            margin-right: 40px;
            cursor: pointer;
            &:first-child {
              padding-left: 0px;
              padding-right: 0px;
            }
            &.active {
              color: #2196f3;
              border-bottom: 5px solid #2196f3;
            }
          }
        }
        .profileActionItems {
          float: right;
          text-align: right;
          width: 50%;
          li {
            display: inline-block;
            list-style: none;
            width: auto;
            margin: 0px 5px;
            &:last-child {
              margin-right: 0;
            }
            button {
              background-color: #e0e0e0;
              color: #212121;
              height: 43px;
              width: 110px;
            }
          }
        }
      }
    }
  }
  &.profile-container {
    padding: 20px 0px 0px 0px;
  }
}
#long-menu {
  ul {
    li {
      min-height: 48px;
      font-weight: 400;
      > svg {
        margin-right: 15px;
        font-size: 25px;
      }
    }
  }
}

.mobile-filter {
  display: none;
}
.myreq-container {
  display: block;
}

.myreq-card-details {
  .myreq-header-list {
    background: #fff;
    margin-bottom: 12px;
    padding: 8px;
    border-radius: 4px;
    // border: 1px solid #ddd;
    div {
      padding: 0 5px;
    }
  }
  .myreq-sublist {
    width: fit-content;
    display: inline-block !important;
  }
}

@media screen and (max-width: 600px) {
  .myreq-container {
    display: none;
  }
  .mobile-filter {
    display: block;
    text-align: right;
  }
  .container-center {
    margin: 0 !important;
    // padding: 0 !important;
    .donateNowBtn {
      width: 90% !important;
    }
  }
  .new-request-paper {
    display: none;
    margin-top: 30px;
  }
}
.filter-st {
  width: 92%;
  float: left;
  padding: 0 30px;
}
.filter-bg {
  width: 6%;
  float: right;
  top: -3px !important;
}
.slick-next:before,
.slick-prev:before {
  color: rgba(0, 0, 0, 0.3) !important;
}

.filter-st .slick-initialized .slick-slide {
  width: auto !important;
}
.slick-slider .slick-track {
  width: max-content !important;
  margin-left: unset;
  margin-right: unset;
}
.last-slider .slick-slider .slick-track {
  margin-left: unset;
  margin-right: unset;
}
@media screen and (max-width: 1024px) {
  .sidemenu-container {
    min-width: auto;
  }
  .sidemenu-container .myOrganizations .grid-item {
    width: 30%;
  }
  .filter-st {
    width: 90%;
  }

  .slick-slider .slick-track {
    width: max-content !important;
  }
}
@media screen and (max-width: 480px) {
  .filter-st {
    width: 82%;
  }
  .filter-st .slick-slide {
    width: 150px !important;
  }
}
.tal-leader {
  text-align: center;
  margin-bottom: 15px !important;
  img {
    border-radius: 4px;
    width: 96%;
  }
  h5 {
    color: #ee2424;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    margin-bottom: 0px;
  }
  svg {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-top: 10px;
  }
  a {
    img {
      width: 25px;
      height: 25px;
      margin-top: 10px;
    }
  }
}
.home-banner {
  @media screen and (max-width: 768px) {
    margin-bottom: 0 !important;
  }
  .slick-next {
    right: 15px;
  }
  .slick-prev {
    left: 15px;
    z-index: 2;
  }
  .slick-prev:before,
  .slick-next:before {
    opacity: 1;
  }
  .slick-slide {
    img {
      width: 100%;
    }
  }
}

.home-banner {
  @media screen and (min-width: 960px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    margin-top: -20px !important;
  }
  .slick-next {
    right: 15px;
  }
  .slick-prev {
    left: 15px;
    z-index: 2;
  }
  .slick-prev:before,
  .slick-next:before {
    opacity: 1;
  }
  .slick-slide {
    img {
      width: 100%;
    }
  }
}
.mobileSlider {
  @media screen and (max-width: 959px) {
    margin-top: -20px;
    display: none;
  }
  a {
    img {
      width: 100%;
      margin-bottom: -6px;
    }
  }
  .slick-next {
    right: 20px;
  }
  .slick-prev {
    left: 20px;
    z-index: 1;
  }
  /*@media screen and (max-width: 768px) {
    display: none;
  }*/
}
.order-col-2 {
  @media screen and (max-width: 959px) {
    order: 2;
  }
}
.order-col {
  @media screen and (max-width: 959px) {
    order: 1;
  }
}

.slider-full-home {
  img {
    width: 100%;
    border-radius: 4px;
  }
}
.quick-menu {
  transition: 0.5s;
  margin-bottom: 23px;
  text-align: start;
  padding: 15px;   
  filter: drop-shadow(0 0 9px #e8eaf5);
  background-color: #ffffff;
  border-radius: 4px;

  p {
    font: 600 12px "Roboto";
    margin: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    @media screen and (max-width: 1024px) {
      justify-content: space-between;
    }

    li {
      display: flex;
      flex-direction: column;
      width: 87px;
      white-space: normal;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      p {
        font: 400 13px "Roboto";
      }
      div {
        background-color: #d9d9d9;
        border-radius: 50px;
        padding: 10px;
        margin-bottom: 10px;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
