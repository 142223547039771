@import "../../sass/variables";
@import "../../sass/mixins";

.plasma {
  // width: 60%;
  padding: 25px;
  display: inline-block;
  @media (max-width: 600px) {
    width: 100%;
    padding: 20px;
  }
}

.close-organizatin {
  margin: 0;
  :hover {
    color: rgb(205, 38, 39);
  }
}
.org-container {
  .org-title {
    color: #058ad0;
    font: 600 20px "Roboto";
    text-transform: uppercase;
  }
}
