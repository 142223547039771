@import "../../sass/variables";
@import "../../sass/mixins";

.make-new-req-container {
  .template-slot {
    padding: 10px 20px;
    p {
      margin: 0;
    }
    h5 {
      padding: 10px 0;
      border-bottom: 1px solid rgb(246, 247, 251);
      color: var(--tal_primary);
      font-weight: normal;
    }
  }
  .email-hedar {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid rgb(246, 247, 251);
    h4 {
      color: rgb(205, 38, 39);
      margin: 0;
      text-align: center;
    }
    button {
      border: 1px solid #ccc;
    }
  }

  .container-center {
    padding: 20px;
    border-radius: 4px;
    // :hover {
    //   background: rgb(205, 38, 39) !important;
    //   color: white;
    // }

    .stepper-title {
 
      font-size: 26px;
      font-weight: 600;
      text-align: center;
      color: var(--tal_primary) ;
    }

    .sub-hr {
      width: 50px;
      margin: 14px auto;
      border: 1px solid  var(--tal_primary) ;
      justify-content: left;
    }
  }
}
