.title_tag {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 12px;
  }
  h2 {
    color: #000;
    font-weight: 700;
    font-size: 2em;
    position: relative;
    &::after {
      height: 2px;
      width: 150px;
      background-color: rgb(243, 90, 87);
      content: " ";
      position: absolute;
      bottom: -10px;
      left: 0;
    }
  }
  .lan_select {
    ul {
      display: flex;
      flex-direction: row;
      gap: 8px;
      li {
        border: 1px solid rgb(243, 90, 87);
        border-radius: 4px;
        &.lan_selected {
          background-color: rgb(243, 90, 87);
          color: #fff;
        }
      }
    }
  }
}
.lan_chips {
  margin-top: 25px;
  li {
    overflow: visible;
    background-color: #e1e1e1 !important;
    border-radius: 40px;
    text-align: center;
    margin: 0 3px !important;
    display: block !important;
    width: unset !important;
    &.lan_chip_selected {
      background-color: rgb(243, 90, 87) !important;
      color: #fff;
    }
    @media screen and (max-width: 600px) {
      min-height: unset;
    }
  }
}
.blog_section {
  h2 {
    color: #000;
    font-weight: 700;
    font-size: 2em;
    text-align: center;
  }
  .blog_btns {
    display: flex;
    justify-content: center;
    gap: 18px;
    margin-top: 30px;
    > div {
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
      padding: 30px 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      width: 300px;
      border-radius: 3%;
      background: #fff;
      a {
        color: #cf3b3b;
        background: #ffbfbf;
        font-size: 18px;
        font-weight: 600;
        border-radius: 10px;
        padding: 10px 0;
        display: flex;
        gap: 10px;
        width: 80%;
        justify-content: center;
        align-items: center;
        text-decoration: none !important;
        margin-top: 40px;
      }
    }
  }
}
.podcast_child_cont {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background: #fff;
  max-width: 100%;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(166, 166, 166, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(166, 166, 166, 1);
  box-shadow: 0px 0px 5px 0px rgba(166, 166, 166, 1);
  border-radius: 10px;
  padding: 15px;
  .podcast_detail_cont {
    margin-top: 10px;
  }
  .player_cont {
    max-width: 100%;
    border-radius: 10px;
  }
}
.podcast_recent_upload {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 35px;
  align-items: stretch;
  margin: 25px 10px;
}

.main_tab {
  header {
    background-color: #fff;
    color: #ddd;
    width: -webkit-max-content;
    width: max-content;
    margin: -18px auto 0;
    border-radius: 40px;
    position: relative;
    z-index: 1;
  }
}
.PrivateTabIndicator-colorSecondary-30 {
  background-color: #fcfdff;
}
.main_box {
  background-color: #283e4a;
  padding: 70px 0;
  margin-top: -25px;
  @media screen and (max-width: 599px) {
    padding: 30px 0;
  }
  h2 {
    color: #fff;
    font-size: 42px;
  }
  p {
    color: #fff;
    font-size: 24px;
  }
}
.menu_select {
  min-height: unset !important;
  button {
    min-height: unset !important;
    text-transform: capitalize !important;
    padding: 16px 35px;
    font-size: 18px;
  }

  button.Mui-selected {
    background-color: rgb(243, 90, 87);
    color: #fff !important;
    border-radius: 40px;
  }
}
.side_new {
  margin-bottom: 50px;
  > h2 {
    color: #000;
  }
}

.mid_row {
  display: flex;
  p {
    color: #353535;

    font-size: 16px;
    font-style: normal;

    line-height: normal;
  }
}
.name_align {
  display: flex;
  background-color: #fff;
  box-shadow: 5px 10px #fff;
  padding: 15px;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 25px;
  @media screen and (max-width: 959px) {
    align-items: unset;
  }
  @media screen and (max-width: 599px) {
    > div {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: unset;
      }
    }
  }
  .img_align {
    display: flex;
    gap: 10px;
    flex-direction: column;
    > div {
      display: flex;
      gap: 10px;
    }
    .name_txt {
      .name_title {
        font-size: 18px;
        font-weight: 600;
      }
      p {
        margin-bottom: unset;
        span {
          font-weight: 600;
        }
      }
    }
  }
}

.menu_select > div > div + span {
  display: none;
}
.name_pro_box {
  span {
    background-color: #f2f2f2;
    padding: 8px 10px;
    border-radius: 10px;
    font-weight: 500;
  }
}
.link_btn {
  &:hover {
    background-color: unset !important;
  }
}
.act_btn_tal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 959px) {
    flex-direction: row;
  }
  button {
    color: #cf3b3a;
    border: 1px solid #cf3b3a;
    padding: 7px 50px;
    margin-left: -20px;
    width: 45%;

    > span {
      display: flex;
      gap: 5px;
    }
  }
}
.le_text_algin {
  margin-left: 110px;
  @media screen and (max-width: 960px) {
    margin-left: 0;
  }
}
.commemt_align {
  padding: 30px;
  .comment_color {
    display: flex;
    justify-content: space-between;
    width: 530px;
    border-radius: 6px;

    h4 {
      color: #000 !important;
    }

    .close_icon {
      cursor: pointer;
    }
  }

  .tal_line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //flex-wrap: wrap;
    margin-top: 30px;
    gap: 18px;
    @media screen and (max-width: 599px) {
      gap: 10px;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
    .round_circle {
      padding: 2px 9px;
      background-color: #ff8300;
      border-radius: 50%;
      color: #fff;
    }
    .leader_pop {
      width: 95%;
      background: #eee;
      border: none !important;
      border-radius: 25px;
      padding: 5px 10px;
      .MuiInput-underline:before,
      .MuiInput-underline:after {
        display: none !important;
      }
      @media screen and (max-width: 599px) {
        width: 80%;
      }
    }
    .leader_pop > span {
      color: #606060;
      font-size: 12px;
    }

    .submit_text {
      background-color: #f2f2f2;
      padding: 7px;
    }
    .pop_para {
      margin-bottom: -24px;
    }
    .pop_para > p {
      font-size: 12px;
      margin: 37px;
    }
  }
}
.leader_line {
  margin-top: 20px;

  .leader_group {
    transition: 0.5s;
    margin-bottom: 23px;
    text-align: start;
    padding: 15px;
    // filter: drop-shadow(0 0 9px #e8eaf5);
    background-color: #ffffff;
    border-radius: 4px;
    width: 93%;

    :nth-child(2) {
      color: #646464;
    }
  }
  .head_leader {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
  }
  .para_line {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #232323;
  }

  .MuiButton-text {
    padding: 5px 3px !important;
  }
}
.Veiw_Profile {
  button {
    background: #ffd7d7;
    color: #cf3b3a;
    height: 36px;
    &:hover {
      background: #ffd7d7;
      color: #cf3b3a;
    }
  }
}

.tabs-chips {
  font-weight: 500;
  font-size: 10px !important;
  cursor: default !important;
}
.comment-btn {
  cursor: pointer;
  text-align: right;
  font-size: 16px;
}

.comments-area {
  width: 100%;
  background-color: #e1e1e1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #606060;
}
.image_style {
  width: 86px;
  height: 86px;
  flex-shrink: 0;
  border-radius: 6px;
}
.recent-comments {
  width: 100%;
  margin: 10px 0;
  display: flex;
  .comment-avatar {
    margin-top: 5px;
    display: inline-block;
    width: 40px !important;
    height: 40px !important;
    vertical-align: text-bottom !important;
    .comment-avatar {
      width: 100% !important;
    }
  }
  .comments-main-details {
    display: inline-block;
    padding: 10px;
    width: 100%;
    padding-left: 20px;
    margin-left: 5px;
    background: #eee;
    border-radius: 25px;
    margin-bottom: 13px;
    .comment-details {
      .commentor-name {
        // font-family: 600 14px $font-regular;
        color: #333;
      }
      .comment-time {
        // font-family: 500 12px $font-regular;
        color: #aaa;
      }
    }
  }
}
.comments-edit-field {
  width: 100% !important;

  div {
    &:before {
      border-bottom: none !important;
    }
    &:hover {
      border-bottom: none !important;
    }
    &:after {
      border-bottom: none !important;
    }
  }
}
.profile_imgs {
  width: 17.212px;
  height: 16.798px;
  flex-shrink: 0;
  margin-left: 4px;
  margin-bottom: 3px;
}
.Veiw_Profile {
  button {
    text-transform: capitalize;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.Dialog-Box {
  .MuiDialog-paper {
    overflow-x: hidden;
  }
}
.name_pro_boxs {
  button {
    text-transform: capitalize;
    &:hover {
      background: unset !important;
    }
  }
  span {
    color: #606060;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.Ares_interst {
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;
}
