.podcast {
  .chevor-icon {
    width: 48px;
    margin: 0 auto;
  }

  .player {
    width: 290px;
    embed {
      height: 100px;
      background-color: #fcfcfc;
      border-radius: 5px;
      width: 280px;
    }
  }

  .hamburger-icon {
    position: fixed !important;
    z-index: 10;
    background-color: white;
    border: 2px solid #eee !important;
    padding: 12px 14px;
    margin: 30px 0 0 12px;
    color: black !important;
    &:hover {
      background-color: var(--tal_primary) !important;
      border: 2px solid #eee;
      color: white !important;
    }
  }
}
.pod-bar{
  position:sticky;top:84px; background:  #d9e3e8;box-shadow:0 0 4px #c6c6c6;
  > div > div {
    align-items: center;
    @media screen and (max-width:480px) {
      >div {margin-bottom: 10px;}
    }
  }
  .slct-pod{
    width: 280px;
    padding-left: 10px;
    @media screen and (max-width:768px) {
      width: 100%;
    }
    @media screen and (max-width:1024px) {
      width: 100%;
    }

  }
}
.pod-search{
  margin-left: 10px;  overflow:hidden;
  > div{border-radius: 5px; margin-top: 10px; padding:4px 0;}
  @media screen and (max-width:480px) {
    width: 100%;
    margin-left: 0;
  }
  > div {background: #f6f6f6; margin-bottom: 10px;}
}
.pod-results{
  width: 100%;
   margin:0px;
   padding-top: 10px;
   display: flex;
   justify-content: flex-start;
   background: #fff;
}
