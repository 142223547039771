.ck-editor-sc .ck-rounded-corners .ck-editor__main .ck-content {
  height: 200px !important;
}
.ship_amt {
  display: flex;
}
.MuiInput-underline:before,
.MuiInput-underline:after {
  display: none;
}
.focus-disabled .MuiSelect-select:focus {
  background-color: unset !important;
}
.request-two-step {
  margin-top: 10px;
}
.currency-line {
  display: flex;
  align-items: center;
  margin-left: 0 !important;
}
.blood_Event {
  label {
    top: 0;
    > div {
      background: #fff;
    }
  }
}
.bgcolor-white input {
  background: #fff;
  padding: 16px !important;
  font-size: 15px;
}
