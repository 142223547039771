@import "../../sass/variables";
@import "../../sass/mixins";



.fixed_donate_now {
    position: sticky;
    padding: 10px 40px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    width: 100%;
    box-shadow: 0 0 10px #f1f1f1;

    button {
        width: 100%;
        margin: 0 !important;
        background: var(--tal_primary);
        color: white;
        padding: 5px;
        font-weight: 600;
        font-size: 14px;
    }
}

.don-details-container {
    .shear-btn {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 400px) {
            flex-direction: column;
        }
    }

    .request-step-two {
        z-index: 0;
        position: relative;
    }

    .MuiInputLabel-outlined {
        z-index: 0;
    }

    .tab_space {
        padding: 20px;

        @media screen and (max-width: 960px) {
            padding: 10px 0px;
        }
    }

    .request-step-two {
        border: 1px solid #f1f1f1;

        padding: 10px;
    }

    .PrivateTabIndicator-colorSecondary-25 {
        border-bottom: 2px solid var(--tal_primary);
    }

    .MuiTabs-flexContainer {
        border-bottom: 1px solid #bdbdbd;
    }

    .icon-btn {
        display: flex;
        float: right;

        @media screen and (max-width: 400px) {
            flex-direction: column;
            float: none;
        }
    }

    .amount-btn {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @media screen and (max-width: 400px) {
            flex-direction: column;
        }
    }

    .image .req-date {
        padding: 5px 0;
    }

    .donationrequst-edit {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        @media screen and (max-width: 600px) {
            padding: 0;
        }

        @media screen and (max-width: 400px) {
            padding-left: 0px;
            flex-direction: column;
        }

        div {
            width: 100%;
            padding-right: 10px;

            @media screen and (max-width: 400px) {
                margin: 5px 0;
                padding: 0;
            }
        }
    }

    .comments-container {
        padding: 0;
    }

    .MuiAlert-icon {
        margin-right: 0 !important;
    }

    .image-gallery-icon {
        z-index: 1 !important;
    }

    .image-gallery-thumbnails-container {
        flex-wrap: wrap;
        display: flex;
        background: #fcfcfc;
        padding: 5px;
    }

    .image-gallery-thumbnail {
        max-height: 100px;
        min-height: 100px;
        max-width: 100px;
        min-width: 100px;
        overflow: hidden;
        border: 1px solid #d5d5d5;
        margin-right: 10px;
    }

    .admin-statuse-requast-main {
        position: sticky;
        top: 79px;
        margin-top: -25px;
        margin-bottom: 20px;
        z-index: 1;

        @media screen and (max-width: 960px) {
            top: 128px;
        }

        .MuiAlert-root {
            .MuiAlertTitle-root {
                color: white;
                margin: 0;
            }

            .MuiAlert-action {
                color: white;
            }
        }
    }

    figure {
        margin: 0;
        padding: 0;
    }

    .chevor-icon {
        width: 48px;
        margin: 0 auto;
    }

    .head-container {
        img {
            max-width: 100%;
            min-width: 100%;
        }

        .image {
            position: relative;

            .image-gallery {
                padding: 10px 20px;

                @media screen and (max-width: 600px) {
                    padding: 10px;
                }

                .image-gallery-slide-wrapper {
                    z-index: 0;

                    div {
                        &:focus {
                            outline: none !important;
                        }

                        // img {
                        //   background-color: #fcfcfc;
                        // }
                    }
                }
            }

            .image-gallery-left-nav,
            .image-gallery-right-nav {
                top: 45%;
            }

            .image-gallery-left-nav .image-gallery-svg,
            .image-gallery-right-nav .image-gallery-svg {
                height: 40px;
                width: 40px;
            }

            .image-gallery-slide {
                background: #fafafa;
                display: grid;
                place-content: center;

                video,
                img {
                    min-height: 150px !important;
                    max-height: 500px;
                }

                // .image-gallery-image {
                //   max-height: unset;
                // }
            }

            .donat-title {
                font-weight: 500;
                font-size: 18px;
                padding: 15px 20px 7px 20px;
                width: 100%;

                display: inline-block;
                vertical-align: middle;

                &::first-letter {
                    text-transform: capitalize;
                }
            }

            .postTitle-fav {
                float: right;
            }

            .title-line {
                padding: 20px;
            }

            .req-date {
                font-size: 16px;
                font-weight: 400;
                padding: 5px 0 5px 20px;

                @media screen and (max-width: 400px) {
                    padding: 5px 10px;
                }

                .category {
                    p {
                        margin: 4px;
                        font: 400 14px $font-regular;
                    }

                    .share-req-icon {
                        &:hover {
                            background: transparent;
                        }
                    }

                    .action-btn {
                        padding: 0 12px;
                        text-align: center;

                        @media screen and (max-width: 400px) {
                            padding: 10px;
                            margin: 5px 0;
                            border-radius: 0;
                            box-shadow: 0 0 5px #f1f1f1;
                        }

                        &:hover {
                            background: transparent;
                        }
                    }
                }
            }

            .react-slideshow-wrapper {
                position: static;

                .each-slidesss {
                    height: 380px;
                    position: relative;
                    background: #e5e5e5;
                }

                img {
                    width: 100%;
                    max-height: 100%;
                    max-width: 100%;
                    height: 100%;
                    border-radius: 4px;

                    position: absolute;
                    top: 0;
                    bottom: 0;

                    margin: auto;
                }
            }

            .details-child {
                width: 100%;

                padding-top: 10px;
                display: inline-block;

                .adding-container {
                    width: 100%;

                    display: inline-block;
                    text-align: start;

                    p {
                        width: 100%;
                        text-align: center;
                        display: inline-block;

                        font: 500 16px "Roboto";
                        color: #000;

                        span {
                            cursor: pointer;
                            width: 100%;
                            display: inline-block;
                            padding: 10px;
                            position: relative;
                            border: 0.5px solid #000;
                            border-radius: 5px;

                            .add-circle {
                                position: absolute;
                                right: 5px;
                                top: 16px;

                                @media screen and (max-width: 600px) {
                                    right: -4px;
                                    top: 44px;
                                    background: white;
                                    box-shadow: 0 0 5px grey;
                                    border-radius: 50px;
                                }
                            }
                        }
                    }
                }

                .share-container {
                    width: 30%;
                    display: inline-flex;
                }

                h3 {
                    width: 100%;
                    margin: 0 auto;
                    padding: 10px 0;

                    div {
                        width: 49%;
                        display: inline-block;

                        &:first-child {
                            font: 500 22px $font-regular;
                            text-transform: uppercase;
                        }

                        &:last-child {
                            text-align: end;
                            font: 500 18px $font-regular;

                            span {
                                padding: 6px;
                                margin: 0 6px;
                            }
                        }
                    }
                }

                .donation-header {

                    font-weight: 600;
                    color: var(--tal_primary);
                    font-size: 18px;

                    img {
                        width: 100%;
                    }
                }

                .donation-text {
                    font-size: 15px;
                    font-weight: 400;
                    word-wrap: break-word;
                    line-height: 1.5em;
                    margin-bottom: 20px;
                }
            }

            .share-option-btns {
                width: 100%;
                margin: 10px 0;
                display: flex;
                justify-content: space-between;

                @media screen and (max-width: 600px) {
                    flex-direction: column;
                }

                :nth-child(3) {
                    margin-right: 0;
                }

                button {
                    width: 100%;
                    color: #fff;
                    padding: 5px 30px;
                    margin-right: 12px;

                    @media screen and (max-width: 600px) {
                        margin: 2px 0;
                    }

                    &.whatsapp {
                        background: #4baf4f !important;
                        padding: 6px;
                    }

                    &.facebook {
                        background: #3b5997 !important;
                        padding: 6px;
                    }

                    &.donatee {
                        background: #795547 !important;
                    }
                }

                svg {
                    margin-right: 10px;
                }
            }

            .fundraiser-container {
                padding: 15px;

                min-width: 100% !important;
                vertical-align: top;
                min-height: 100%;

                .fundraiser-title {

                    font-size: 20px;
                    font-weight: 500;
                    color: #565656;
                    border-bottom: 2px solid #eee;
                    padding-bottom: 10px;
                }

                .date {
                    border-right: 2px solid #ccc;
                    padding-right: 10px;
                }

                .avatar-container {
                    background: transparent;
                    width: 60px;
                    height: 60px;
                }
            }
        }

        .title-container {
            // display: inline-block;

            vertical-align: top;

            .submit-post,
            .facebook-post {
                color: #fff;
                padding: 10px 30px;
                width: 100%;
                margin: 10px 0;

                svg {
                    margin-right: 10px;
                }
            }

            .submit-post {
                margin-top: 0;
                background: #4baf4f;
            }

            .facebook-post {
                background: #3d5998;
            }

            h4 {
                font: 500 18px $font-regular;
                text-transform: uppercase;
                padding: 10px 0;
                margin: 10px 0;
                border-bottom: 1px solid #ccc;
            }

            p {
                text-transform: capitalize;

            }

            section {
                border-radius: 4px;
                margin-bottom: 15px;






                .amount-text {
                    padding: 15px 15px 0 15px;

                    .payment-header {
                        font-weight: 500;
                        font-size: 16px;

                        border-bottom: 2px solid #eee;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                    }

                    .payment-desc-container {
                        .contact-information {
                            svg {
                                padding-right: 8px;
                            }
                        }

                        p {
                            font-weight: 400;
                            font-size: 14px;
                        }

                        .payment-info {
                            padding: 0;
                        }

                        .payment-sub-header {
                            font-size: 16px;
                            font-weight: 500;
                            padding: 10px 0;
                        }

                        .payment-btn-container {
                            padding: 0;
                            text-align: center;
                            width: 100%;

                            div {
                                text-align: left;
                            }

                            button {
                                padding: 10px 0;
                                background: #9e9e9e;
                                font: 500 12px $font-regular;
                                border-radius: 3px;
                                color: #fff;
                                margin-right: 5px;
                                margin-bottom: 5px;
                                min-width: 50px;

                                @media screen and (max-width: 600px) {
                                    margin: 5px 0;
                                    flex-wrap: wrap;
                                }

                                &.other-btn {
                                    width: 100% !important;
                                    margin-top: 10px;

                                    @media screen and (max-width: 600px) {
                                        margin-top: 5px;
                                    }
                                }

                                &.activeColor {
                                    background: $primary-button;
                                }
                            }
                        }
                    }
                }

                .progress-text {
                    padding: 10px 0;
                    display: flex;
                    justify-content: space-between;
                }

                .page-grid {
                    .MuiTablePagination-spacer {
                        flex: inherit;
                    }

                    .MuiTablePagination-toolbar {
                        padding: 0;
                        display: flex;
                        justify-content: space-between;
                    }

                    .MuiTablePagination-selectRoot {
                        margin-right: 5px;
                    }

                }


            }

            // section:nth-child(2){
            //   @media screen and (max-width: 600px) {
            //     position: fixed;
            //     bottom: -14px;
            //     width: 94%;
            //     }
            // }

            .details-topReqListBlk {
                padding: 16px 0;

                .topReqList {
                    text-align: left;

                    .topReqListTitle {
                        background-image: url("https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/material3/ic_beenhere_48px.svg");
                        background-repeat: no-repeat;

                        font-size: 14px;
                        font-weight: bold;
                        padding: 0px 0px 7px 40px;
                        background-size: 10%;
                    }
                }

                ul {
                    margin: 0px;
                    padding: 0px;

                    li {
                        list-style: none;
                        padding: 20px 0px 20px;
                        border-bottom: 1px solid #e0e0e0;

                        img {
                            height: 40px;
                            float: left;
                            padding-right: 10px;
                        }

                        span {
                            color: #0067ba;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .footer-sec {
            margin: 5px 15px;
            padding: 0;
            text-align: right;

            @media screen and (max-width: 600px) {
                margin: 0 15px;
            }

            .donate-btn {
                @include submitButton;
                width: 100%;
                padding: 10px;

                @media screen and (max-width: 600px) {
                    margin: 5px 0 10px 0;
                }
            }

            .share-btn {
                background: #ffdede;
                padding: 10px;
                margin-left: 10px;
            }
        }
    }

    .donation-details-body {
        width: 90%;
        margin: 0 auto;

        .details-child {
            width: 55%;
            display: inline-block;

            .adding-container {
                width: 100%;
                margin: 0 0 30px 0;
                text-align: start;

                p {
                    width: 27%;
                    display: inline-block;
                    margin: 0 20px;
                    font: 500 16px $font-regular;
                    color: #000;

                    span {
                        background: #fff;
                        cursor: pointer;
                        width: 100%;
                        display: inline-block;
                        padding: 10px;
                        position: relative;
                        border: 0.5px solid #000;
                        border-radius: 5px;
                        margin: 0 5px;

                        .add-circle {
                            position: absolute;

                            top: 20px;
                        }
                    }
                }
            }

            h3 {
                width: 100%;
                margin: 0 auto;
                padding: 10px 0;
                border-bottom: 2px solid #aaa;

                div {
                    width: 49%;
                    display: inline-block;

                    &:first-child {
                        font: 500 22px $font-regular;
                        text-transform: uppercase;
                    }

                    &:last-child {
                        text-align: end;
                        font: 500 18px $font-regular;

                        span {
                            padding: 6px;
                            margin: 0 6px;
                        }
                    }
                }
            }

            .donation-header {
                font: 800 22px $font-regular;
            }

            .donation-text {
                font: 500 18px $font-regular;
            }
        }
    }

    .plasma-btn {
        @include submitButton;
        width: 100%;
        padding: 10px;

        @media screen and (max-width: 600px) {
            margin: 5px 0 10px 0;
        }
    }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
    .head-container {
        width: 100%;

        .spacing_mobile {


            .image {
                display: block !important;
                width: 100% !important;
            }

            & .title-container {
                display: block !important;

                width: 100% !important;
                position: static !important;
            }
        }

        .don-details-container {
            .head-container {
                .title-container {
                    display: inline-flex;
                    padding: 0px;
                }
            }
        }
    }
}

@media screen and (min-width: 1920px) {
    .payment-btn-container button {
        width: 17% !important;

        &:last-child {
            width: 93% !important;
        }
    }
}

.don-details-container .hamburger-icon {
    position: fixed;
    z-index: 10;
    background-color: white !important;
    border: 2px solid #eee;
    padding: 12px 14px;
    margin: 30px 0 0 12px;
}

.don-details-container .hamburger-icon:hover {
    background-color: var(--tal_primary) !important;
    border: 2px solid white;
    color: white !important;
}

@media screen and (max-width: 960px) {
    .don-details-container {
        .hamburger-icon {
            position: fixed;
            z-index: 10;
        }
    }

    .head-container {
        width: 100%;

        .image {
            display: block !important;
            width: 100% !important;

            .share-option-btns button {
                width: 100% !important;
            }
        }

        & .title-container {
            display: block !important;

            margin: 0 !important;
            width: 100% !important;
            position: static !important;
        }
    }

    .details-child {
        width: 100% !important;
        padding: 10px !important;

        h3 div {
            width: 100% !important;
        }

        .adding-container p {
            width: 100% !important;
        }
    }

    .other-btn {
        width: 90% !important;
    }

    .fundraiser-container {
        width: 90% !important;
    }
}

.spam-text {
    width: 60%;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
}

.don-details-container .head-container .title-container {
    min-width: 100%;
}

.head-container {
    @media all and (min-width: 960px) and (max-width: 1280px) {
        .makeStyles-inputRoot-13 {
            justify-content: flex-end;
        }
    }

    .rs-button {
        margin: 0 4px;
    }

    .rs-button button {
        border: 2px solid #fff;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }
}

.ck-rounded-corners {
    height: 245px !important;

    .ck-editor__main {
        height: 200px;

        .ck-content {
            height: 200px !important;
        }
    }
}