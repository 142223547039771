@import "../../sass/variables";
@import "../../sass/mixins";

.select-your-location {
  .fundraiserFor {
    height: 50px;
    border: 0px solid #ccc;
    background: #fff;
    padding: 10px 0;
  }
}
.make-new-req-container {
  .input-field {
    border-radius: 4px;
  }



  .hamburger-icon {
    position: fixed !important;
    z-index: 10;
    background-color: white;
    border: 2px solid #eee !important;
    padding: 12px 14px;
    margin: 30px 0 0 12px;
    color: black !important;
    &:hover {
      background-color: var(--tal_primary) !important;
      border: 2px solid #eee;
      color: white !important;
    }
  }

  .container-center {
    padding: 20px;
    border-radius: 4px;

    .MuiStepper-root {
      padding: 0;
      .MuiStep-horizontal {
        padding: 0;
        span {
          padding: 0 2px;
        }
      }
    }
    .stepper-title {
      font: 600 26px $font-regular;
      text-align: center;
      color: var(--tal_primary) ;
    }
    label {
      background: #fff;
      padding: 0 10px;
    }
    .sub-hr {
      width: 50px;
      margin: 14px auto;
      border: 1px solid #d34040;
    }
    .stepper-subheader {
      font: 400 16px $font-regular;
      text-align: center;
      font-weight: bold;
    }
    .MuiStepIcon-active,
    .MuiStepIcon-completed,
    .MuiStepLabel-active,
    .MuiStepLabel-completed {
      color: #5bca7a;
    }

    .request-step-one {
      background-image: url("/images/add-req-1.png");
      background-position: right bottom;
      background-size: 40%;
      background-repeat: no-repeat;
      @media screen and (max-width: 960px) {
        background-image: none;
      }
      .make-request-form {
        width: 63%;
        @media screen and (max-width: 960px) {
          width: 100%;
        }
        // .MuiFormControl-root {
        //   margin: 16px 0 !important;
        // }
        fieldset {
          border: none !important;
        }
        .bg-normal {
          border: 1px solid #ccc;
        }
        .bg-green {
          border: 1px solid #34bf60;
        }
        .bg-red {
          border: 1px solid #fd2a2a;
        }
        .amount-label {
          position: absolute;
          top: -9px;
          left: 11px;
          background: #fff;
          z-index: 1;
          padding: 0 10px;
        }
        .title-length {
          font-size: 18px;
          margin-left: 5px;
          position: absolute;
          top: 12px;
          color: #ccc;
          right: 1px;
          padding: 9px;
          background: #fff;
        }
        .dob {
          width: 100%;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          div {
            &:before {
              border-bottom: none !important;
            }
          }
          label {
            padding-left: 10px;
            position: absolute;
            top: -9px;
            background: #fff;
            left: 4px;
          }
          div {
            padding-left: 15px;
          }
          button {
            outline: none !important;
            top:-8px;
          }
          input {
            border: none !important;
          }
        }
        .req-currency {
          position: absolute;
          top: 22px;
          left: 15px;
          z-index: 1;
          &.MuiInput-underline-327 {
            border-bottom: none !important;
          }
          .Mui-focused {
            border-bottom: none !important;
            box-shadow: none;
            div {
              border-bottom: none !important;
              box-shadow: none;
              &:before,
              &:hover,
              &:focus,
              &:active {
                border-bottom: none !important;
                box-shadow: none;
                &:hover,
                &:focus {
                  border-bottom: none !important;
                  box-shadow: none;
                }
              }
            }
            &:before,
            &:hover,
            &:focus,
            &:active {
              border-bottom: none !important;
              box-shadow: none;
              &:hover,
              &:focus {
                border-bottom: none !important;
                box-shadow: none;
              }
            }
          }
          div {
            &:before,
            &:hover,
            &:focus,
            &:active {
              border-bottom: none !important;
              box-shadow: none;
              &:hover,
              &:focus {
                border-bottom: none !important;
                box-shadow: none;
              }
            }
          }
          &:before,
          &:hover,
          &:focus {
            border-bottom: none !important;
            &:hover,
            &:focus {
              border-bottom: none !important;
            }
          }
        }
        .request-amount {
          input {
            text-align: end;
            font-size: 30px;
            font-weight: 800;
            color: #444;
            height: 38px !important;
          }
        }
        .request-for {
          label {
            top: 0 !important;
          }
        }
        .recipient,
        .category-select {
          width: 100%;
          margin: 5px 0;
          label {
            padding-left: 10px;
            top: 0;
            left: 6px;
            background: #fff;
            z-index: 1;
          }
        }
      }
      .meta-text {
        font: italic 10px $font-regular;
        color: #bbb;
        text-align: center;
      }
    }

    .request-step-two {
      background-image: url("/images/add-req-2.png");
      background-position: right bottom;
      background-size: 40%;
      background-repeat: no-repeat;
      width: 100%;
      .step-two-title {
        font: 600 14px $font-regular;
      }
      .image-desc {
        font: 400 12px $font-regular;
        color: #aaa;
      }
      
        .req-tooltip{
          span{
             color:white !important;
          }
        }
    }

    .request-step-three {
      background-image: url("/images/add-req-3.png");
      background-position: right bottom;
      background-size: 40%;
      background-repeat: no-repeat;
      width: 100%;
      .step-three-title {
        font: 600 14px $font-regular;
      }
      .story-desc {
        font: 400 12px $font-regular;
        color: #666;
      }
      .agree-text {
        font: italic 12px $font-regular;
        color: #bbb;
        text-align: center;
      }
      .ck-rounded-corners {
        height: 245px !important;
        .ck-editor__main {
          height: 200px;
          .ck-content {
            height: 200px !important;
          }
        }
      }
    }

    .video-url {
      width: 50% !important;
      color: #02958b !important;
      border: 1px solid #02958b !important;
      margin-left: 5px !important;
    }

    .continue-btn {
      background: var(--tal_primary)  !important;
      color: #fff !important;
      width: 63% !important;
      padding: 15px 20px;
      @media screen and (max-width: 960px) {
        width: 100% !important;
      }
    }

    .mid-btn {
      background: var(--tal_primary) !important;
      color: #fff !important;
      width: 335px !important;
      padding: 15px 20px;
    }

    .short-btn {
      background: var(--tal_primary) !important;
      color: #fff !important;
      padding: 15px 20px;
      width: 175px !important;
    }

    .preview-btn {
      background: #d4391b !important;
      color: #fff !important;
      padding: 14px 15px;
      margin-right: 10px !important;
      width: 160px;
    }
  }
}
