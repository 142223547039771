.Fade_style {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  padding: 15px 20px 10px;
  border-radius: 5px;
  border-bottom: 1px solid #cccc;
}
.drop_box_close_organizatin {
  text-align: end;
  cursor: pointer;
  color: rgb(205, 38, 39);
  background-color: #ffd7d7;
  padding: 4px;
  display: grid;
  place-content: center;
  margin: 0;
  border-radius: 4px;
  span {
    color: rgb(205, 38, 39);
  }
  :hover {
    color: rgb(205, 38, 39);
    background-color: #ffd7d7;
  }
  button {
    background: unset !important;
    text-transform: inherit;
  }
}
.head_model {
  font-size: 17px !important;
  font-weight: 600 !important;
  margin-bottom: 5px !important ;
}
.phoneBdr {
  border: 1px solid #ccc;
  padding: 14px;
  border-radius: 5px;
}
.head_style {
  margin-bottom: -30px !important;
}

.save_btn11 {
  display: flex;
  justify-content: end;
  margin-top: 10px;
  button {
    padding: 15px 60px;
  }
}
.plasma {
  width: 100%;
  padding: 25px;
  display: inline-block;
  margin-top: -20px;
}
.close_icon {
  display: flex;
  justify-content: end;
  svg {
    cursor: pointer;
    display: block;
    &:hover {
      color: rgb(205, 38, 39);
    }
  }
}
.Fad_style {
  display: flex;
  margin-top: "20px";
}
.name_title {
  @extend .head_model;
  p {
    color: #eee;
  }
}
.name_text {
  padding-left: 15px !important;
  margin-top: 5px;
}
.text_mrg {
  display: flex;
}
.text_flex {
  @extend .text_mrg;
  margin: 5px;
}
.text_flexs {
  @extend .text_mrg;
  margin: 5px;
  justify-content: end;
  margin-top: 20px;
}
.profile_mrg {
  margin: 20px 0px !important;
}
.profile_p {
  font-size: 10px;
  color: rgb(95, 93, 93);
  margin: 11px;
  span {
    font-size: 5px;
    color: #ccc;
  }
}
.profile_img {
  height: 15px;
  width: 15px;
  margin-right: 9px;
}

.profile_border {
  margin: 20px 0px !important;
  border-left: 2px solid #ff8080 !important;
  padding-left: 10px;
}
.name_ptag {
  background-color: #eee;
  padding: 8px;
  border-radius: 10px;
  margin-left: 10px;
  text-transform: capitalize;
}
.text_mrgn {
  margin-right: 20px;
}

.profile_imgs {
  width: 17.212px;
  height: 16.798px;
  flex-shrink: 0;
  margin-left: 4px;
  margin-bottom: 3px;
}
.image_style {
  width: 105px;
  height: 105px;
  border-radius: 17px;
  border: 4px solid #fff;
  flex-shrink: 0;
}

.Veiw_align {
  text-align: end;
  cursor: pointer;
}