@import "../../sass/variables";
@import "../../sass/mixins";





.topbar {

  .kindness-points {
    padding: 2px;
    z-index: 2;
    position: absolute;
    font-size: 0.75rem;
    min-width: 20px;
    max-width: 100px;
    box-sizing: border-box;
    font-weight: 500;
    line-height: 1;
    border-radius: 5px;
    top: 4px;
    color: #fff;
    background:$logo-background;
    border: 3px solid #283e4a;
  }

  .fix .tab-box {
    left: -150px;
    min-width: 407px;
    // top: 48px;
    @media only screen and (max-width: 960px) {
      left: -307px;
      top:72px;
    }
    @media only screen and (max-width: 450px) {
      left: -28px;
      min-width: 265px;
      position:absolute;
      transform:translate(-50%, 0);
      top:63px;
    }
  }
  .add {
    display: flex;
    width: fit-content;
    flex-direction: row;
    left: -110px !important;
    @media only screen and (max-width: 960px) {
    flex-direction: column;
    left: 5px !important;
    }

    li {
      display: flex;
      flex-direction: column;
      white-space: nowrap;
      @media only screen and (max-width: 960px) {
        flex-direction: row;
        padding-right: 15px !important;
      }
      p {
        @media only screen and (max-width: 960px) {
        margin-right: 10px;
        }

        background-color: #d9d9d9;
        border-radius: 50px;
        padding: 5px;
        width: 50px;
        height: 50px;
        display: grid;
        place-content: center;
        img {
          width: 30px !important;
          height: 30px !important;
        }
      }
      div {
        height: 30px;
      }
    }
  }

  .tab-box {
    position: absolute;
    background: #fff;
    z-index: 9;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 18px;
    left: -2px;
    box-shadow: 0 2px 4px #3a3a3a;
    transform: translate(-3%, -2%);

    hr{
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
    }
    li{
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 960px) {
        justify-content: left;
      }

      span{
        &:nth-child(2){
          background-color: #f6f7fb;
    border-radius: 10px;
    padding: 0 10px;
    font-size: 12px;
    color: #2196f3;
        }
      }
      &:nth-child(1), &:nth-child(2){
       
        padding: 5px 15px;
        &:hover{
          background-color: transparent;
        }
    }


    // &:nth-child(8), &:nth-child(9), &:nth-child(10){
    //   display: flex;
    //   justify-content: space-between;
    // }
    

  }



    span {
      right: -5px;
    }
    p {
      text-align: left;
    }
  }

  .indox {
    padding: 0 !important;

    ul {
      padding-bottom: 0;
      padding-top: 2px;

      .MuiListItemAvatar-root {
        min-width: 0;
      }
      hr {
        margin: 0;
      }
    }
    span {
      font-size: 16px;
      small {
        padding: 0 8px 0 0;
        color: "#919191";
      }
      // white-space: nowrap;
      p:first-child {
        height: 0;
      }
    }

    p {
      color: rgba(0, 0, 0, 0.54);
    }

    .view {
      display: list-item;
      text-align: right;
      font-size: 16px;
      a {
        color: grey;
        text-decoration: none;
        top: -3px;
        position: relative;
      }
      a:hover {
        color: #cd2729;
      }
    }
  }
}

.menu-pull {
  position: relative;
  z-index: 5;
}

.searchbar {
  margin-top: 25px;

  @media only screen and (max-width: 960px) {
    margin-top: 20px;
   
  }

  .makeStyles-searchIcon-10 {
    padding: 6px 4px;
  }
  .makeStyles-inputInput-12 {
    padding: 5px 0 5px 30px;
    height: 25px;
  }

  
}

@media only screen and (max-width: 400px) {
  .post-actions-left {
    min-width: 100%;
    .post-actions-left {
      display: flex;
      justify-content: space-between;
    }
  }
}
.top-icon-btn:hover {
  background-color: rgb(31, 51, 63) !important;
}
.donate-to-tal-mobile{
  @media only screen and (max-width: 960px) {
    margin: 0 !important;
    top: 8px;
    height: 35px !important;
    
  }
}

.top-icon-btn {
  position: relative;
  border-radius: 0 !important;
  border-top: 3px solid rgb(40, 62, 74);
  border-bottom: 3px solid rgb(40, 62, 74);
  margin: 0 1px !important;


  &.active {
    border-top: 3px solid rgb(31, 51, 63);
    border-bottom: 3px solid $logo-background;
    background-color: rgb(31, 51, 63);
  }

  .MuiPopover-paper {
    top: 56px !important;
  }
  p {
    span svg:nth-child(1) {
      font-size: 12px;
    }
    svg {
      position: relative;
    }
    .user-profile-image {
      border-radius: 50%;
      width: 48%;
    }
  }
  span {
    display: inline-block;
    // position: relative;

    p {
      color: #fff;
      position: relative;
      text-align: center;
      font-size: 14px;
      margin-bottom: 2px;
      height: 25px;
      img {
        width: 30%;
        margin-bottom: 0;
      }
      .select-region {
        &:before {
          border-bottom: none !important;
        }
        &:after {
          border: none !important;
        }
        img {
          width: 75%;
          right: -10px;
          top: 2px;
        }
        span {
          display: none;
        }
        svg {
          display: none;
        }
      }
      &:first-child {
        // margin: 0;
        // height: 28px;
        .fa-signal-stream {
          width: 54px;
          height: 24px;
          position: absolute;
          right: 4px;
          top: 8px;
        }
      }
      // &:last-child {
      //   margin: 0;
      //   font-size: 14px;
      // }
    }
  }
  &.last {
    border-right: 1px solid #707070;
    border-radius: 0;
  }
}

.donate-to-tal {
  color: $color-white !important;
  border: 1px solid $logo-background !important;
  height: 31px;
  border-radius: 3px !important;
  margin: 27px 0 27px 15px !important;
  font-size: 10px !important;
  padding: 4px 10px !important;
  font-weight: 600;
  min-width: 100px;
}
.donate-to-tal-mobile {
  color: $color-white !important;
  border: 1px solid $logo-background !important;
  height: 31px;
  min-width: 30px !important;
  border-radius: 3px !important;
  margin:27px 0 27px 5px;
  font-size: 10px !important;
  padding: 4px !important;
  font-weight: 300;
}

.login-btn {
  white-space: nowrap;
  background: $logo-background !important;
  color: #fff !important;
  height: 32px;
  border-radius: 3px !important;
  margin: 26px 0 0 10px !important;
  font-size: 12px !important;
  padding: 15px 10px !important;
  min-width: 108px !important;

  @media only screen and (max-width: 960px) {
    margin: 8px 0 0 0 !important;

  }
}

.my-avatar-cntnr {
  .MuiPopover-paper {
    ul {
      width: 220px;
      // padding: 10px;
      li {
        font: 400 14px "Roboto";

        padding: 10px 15px;
      }
    }
  }
}

.my-profile-cntnr {
  .MuiPopover-paper {
    ul {
      width: 220px;
      // padding: 10px;
      li {
        font: 400 14px "Roboto";
        padding: 15px 25px;
        &:first-child {
          justify-content: center;
          font-weight: 500;
        }
      }
    }
  }
}

.apps-cont {
  padding: 10px 0;
  width: 240px;

  li {
    line-height: 2.5;
    // min-width: 90px;

    span {
      font-weight: 500;
      font-size: 14px;
    }
    .apps-vr {
      margin: 0 30px;
    }
    &:hover {
      background: none;
    }
    .apps-active {
      color: var(--tal_primary);
    }
  }
}

.modal-cntr {
  position: relative;
  &:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }
  .close-icon {
    position: absolute;
    right: -38px;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    padding: 4px 12px;
    opacity: 0.7;
    background: #ccc;
    border-radius: 50%;
  }
}

.mltMobile{display: none;}
.mobi-img img {
  width: 21px;
  height: 21px;
}
@media only screen and (max-width: 1024px) {
  .sctMobile,
  .desktop-view {
    display: none;
  }/*
  .mltMobile {
    visibility: visible;
    display: flex;
    justify-content: end;
    gap: 20px;
    margin-top: 25px;
    position: static;
  }*/
  /* top bar new menu */
  .mltMobile{
    display: flex; float: right; align-items: center;
    .donate-to-tal{
      margin: 0 !important;
    }
  }
  .menu.menubar {
    .burger-menu {
      width: 38px;
      height: 32px;
    }
  }

  .menu {
    .menuItems {
      right: 0 !important;
      left: unset !important;
      background-color: #fff !important;
      .menuItem {
        border-bottom: 1px solid #ddd;
        p {
          font-size: 1rem;
          font-weight: 400;
          position: relative;
          p {
            display: inline;
            img {
              border-radius: 50%;
            }
          }
        }
        p:first-child {
          width: 100%;
        }
        .con-switch {
          background: #f1f1f1;
          padding: 5px;
          border-radius: 50px;
          display: flex;
          justify-content: space-between;
          span {
            background: #fff;
            padding: 3px 6px;
            border-radius: 50px;
            width: 48%;
            text-align: center;
            img {
              width: 32%;
            }
          }
        }
        .mobi-logout {
          background: #f35a57;
          color: #fff;
          padding: 10px 15px;
          border-radius: 4px;
          width: fit-content;
          text-transform: uppercase;
        }
      }
    }
  }
  .menuItems {
    height: 85vh;
    overflow-y: auto;
  }
  .menu .menuItems .menuItem .nextArrow svg,
  .menu .menuItems .back .backArrow svg {
    fill: #f35a57 !important;
    opacity: 0.8;
  }
  .donate-to-tal-mobile {
    top: 0;
  }
}
.msidemenu{
  background-color: unset !important; box-shadow: none !important; 
  @media only screen and (max-width: 1024px) {
    div:nth-child(1){padding-right: 0;}
  }
  span.MuiIconButton-label{
    color: #fff;
  }
}

.accmobile{box-shadow: none !important;}
.accmobile .accsum{
  padding: 0;
  min-height:42px !important;
  :first-child{
    margin: 0;
  }
  :last-child{
    padding: 0;
  }
}
.submenu-mobi{
  padding: 0 !important;
   ul{padding-top: 0; padding-bottom: 0;width: 100%;}
}

.accmobile p svg{width: 1em; height: 1em;}
.menu-mobi{
  box-shadow: none !important;
  .con-switch {
    background: #f1f1f1;
    padding: 5px;
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    span {
      background: #fff;
      padding: 3px 6px;
      border-radius: 50px;
      width: 48%;
      text-align: center;
      img {
        width: 32%;
      }
    }
  }
  .mobi-logout {
    background: #f35a57;
    color: #fff;
    padding: 10px 15px;
    border-radius: 4px;
    width: fit-content;
    text-transform: uppercase;
  }
}
.menu-mobi .talmediamobi{width: 20px; height: 20px;}
.add-fund-mobi{
        span{
          display: flex;
          align-items: center;
        img{
          width: 22px;
          height: 22px;
          margin-right:5px;
        }
  }
}
@media screen and (max-width:960px) {
  .donate-to-tal-mobile{display: none !important;}  
}