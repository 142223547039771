@import "../../sass/variables";
@import "../../sass/mixins";

.updated-button {
  background-color: transparent !important;
  color: #f35a57 !important;
  border-radius: 5px !important;
  border: 1px solid #f35a57 !important;

  :hover {
    background: none !important;
  }
}

.parent-notice {
  color: #000;
  font: 400 12px Roboto;
  background-color: #f8dede;
  padding: 10px;

  .notice {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }
}

.ck-toolbar__items {
  z-index: 0 !important;
}

.no-updates {
  font: italic 20px "Roboto";
  text-align: center;
  padding: 10px;
}
