.Admin_statusBar {
  display: flex;
  justify-content: space-between;
  background: white;
  align-items: center;
  padding: 10px;
  font-weight: 500;
}
.Srch_Export {
  display: flex;
  align-items: center;
  gap: 5px;
}
.main_tab {
  header {
    background-color: #fff;
    color: #ddd;
    width: -webkit-max-content;
    width: max-content;
    margin: -18px auto 0;
    border-radius: 40px;
    position: relative;
    z-index: 1;
  }
}
.name_align {
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 5px #eee;
  padding: 15px;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 25px;
  border: 1px solid #eee;
  border-radius: 10px;
}
.Btn_newLed {
  font-size: 10px;

  span {
    background: #cf3b3a;
    color: white;
    padding: 10px 10px 10px 10px;
    margin: 10px 0 !important;
    border-radius: 6px;
    font-weight: 400;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    text-transform: capitalize;
  }
  &:hover {
    background: unset !important;
  }
}
.Btn_new {
  span {
    background: #eee;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 10px 10px 10px;
    margin: 10px 0 !important;
    border-radius: 5px;
    text-transform: capitalize !important;
  }
  &:hover {
    background: unset !important;
  }
}
.btn_Export {
  border-radius: 5px;
  width: 250px;
}
.Edit_btn {
  display: flex;
  align-items: center;
  border-radius: 5px;
  &:hover {
    background: unset !important;
  }
}
.Export_btn {
  span {
    display: inline-flex;
    padding: 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    color: "#CF3B3A" !important;
    border: 1px solid #cf3b3a;
    background: #fff;
  }
  &:hover {
    background: unset !important;
  }
  img {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    margin-right: 5px;
  }
  p {
    margin-bottom: unset !important;
    text-transform: capitalize;
    color: #cf3b3a;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.act_btn_tal {
  display: flex;
  gap: 10px;
  justify-content: end;
  margin-right: -16px;
}
.nomi_bar {
  margin: 20px 0 0 0;
}
.Approve_btn {
  width: 150px;
  span {
    display: flex;
    gap: 5px;
    color: #29a027;
    border: 1px solid #29a027;
    padding: 5px 20px;
    border-radius: 2px;
    text-transform: capitalize;
  }
  &:hover {
    background-color: unset !important;
  }
}
.Approve_Btn {
  width: max-content;
  span {
    display: flex;
    gap: 5px;
    color: #0a60bb;
    border: 1px solid #0a60bb;
    padding: 5px 20px;
    border-radius: 2px;
    text-transform: capitalize;
  }
  &:hover {
    background-color: unset !important;
  }
}
.Rejct_btn {
  width: 150px;

  span {
    display: flex;
    gap: 5px;
    color: var(--tal_primary);
    border: 1px solid var(--tal_primary);
    padding: 5px 20px;
    border-radius: 2px;
    text-transform: capitalize;
  }
  &:hover {
    background-color: unset !important;
  }
}
.name_pro_box {
  display: flex;
  flex-wrap: wrap;
  text-transform: inherit;
  span {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 10px;
    font-weight: 500;
    margin-left: 16px;
    text-transform: capitalize;
  }
}
.sub_head {
  h2 {
    color: #000;
    font-size: 1.8rem;
  }
  p {
    font-weight: 450;
    font-size: px;
  }
}
.img_align {
  display: flex;
  gap: 10px;
  .name_txt {
    margin-left: 30px;
    .name_title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: unset;
      span {
        font-weight: 600;
      }
    }
  }
}

.name_txt1 {
  display: flex;
  gap: 5px;
  .name_title1 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: unset;
    span {
      font-weight: 400;
    }
  }
}

.le_text_algin {
  margin-left: 60px;
}

.link_btn {
  button {
    margin-left: -50px;
  }
  &:hover {
    background-color: unset !important;
  }
}
.mid_row {
  p {
    color: #646464 !important;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.Sort_slelct {
  display: flex;
  justify-content: end;
  gap: 10px;
  align-items: center;
}
.Select_btn {
  height: 48px;
  width: 200px;
  padding: 10px;
  border-radius: 6px;
  background-color: #fff;
  &::before,
  &::after {
    display: none !important;
  }
  &:hover {
    border-color: unset;
  }
}
.Veiw_Profile {
  button {
    background: #ffd7d7;
    color: #cf3b3a;
    padding: 8px 10px;
    margin-right: -40px;
    text-transform: capitalize;
    &:hover {
      background: #ffd7d7;
      color: #cf3b3a;
    }
  }
}
.Veiw_align {
  text-align: end;
}
.center_style {
  margin-top: 16px;
  margin-left: 16px;
}
.mrg_flex {
  display: flex;
  align-items: center;
}

.btn_fixed {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 2;
}
.btn_Exports {
  height: 48px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #c9c9c9;
  background: #fff;
  display: flex;
}

.Sort_slelcts {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin-bottom: 18px;
  @media screen and (max-width: 899px) {
    flex-wrap: wrap;
  }
  > div {
    &:first-child {
      width: 25%;
      @media screen and (max-width: 899px) {
        width: 100%;
      }
    }
    &:last-child {
      width: 75%;
      @media screen and (max-width: 899px) {
        width: 100%;
      }
    }
  }
}
.sort_by_all {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  @media screen and (max-width: 899px) {
    justify-content: space-between;
  }
  button {
    padding: 0;
  }
  @media screen and (max-width: 680px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.Srch_Exports {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
}

.Rejct_span {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 88.889% */
  letter-spacing: 0.18px;
}
.tab_bar {
  display: flex;
  justify-content: space-between;
  padding: 28px;
  p {
    margin-bottom: unset;
  }
}
.Rejct_Area {
  color: #777;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.14px;
  margin: 20px;
  width: 401px;
  height: 145px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #c9c9c9;
  background: #fff;
  padding: 14px;
}

.Rejct_text {
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
  margin-top: 15px;
  align-items: center;
  button:nth-child(1) {
    padding: 12px;
    text-transform: capitalize;
    color: #8e8e8e;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 52px;
    height: 17.684px;
    flex-shrink: 0;
    margin-right: 5px;
    &:hover {
      background: unset !important;
    }
  }
  button:nth-child(2) {
    padding: 12px 25.3px;
    border-radius: 4px;
    border: 1px solid #29a027;
    background: #29a027;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}

.accept_btn {
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
  margin-top: 15px;
  align-items: center;
  button:nth-child(1) {
    padding: 12px;
    text-transform: capitalize;
    color: #8e8e8e;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 52px;
    height: 17.684px;
    flex-shrink: 0;
    margin-right: 5px;
    &:hover {
      background: unset !important;
    }
  }
  button:nth-child(2) {
    padding: 12px 25.3px;
    border-radius: 4px;
    border: 1px solid #cf3b3a;
    background: #cf3b3a;
    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}
.Rejct_Areas {
  margin: 20px;
  white-space: pre-line;
  line-height: 1.5;
  .Veiw_align {
    text-align: end;
  }
  .center_style {
    margin-top: 16px;
    margin-left: 16px;
  }

  .btn_fixed {
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 2;
  }
  .btn_Exports {
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #c9c9c9;
    background: #fff;
  }

  .Srch_Exports {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
  }

  .Rejct_span {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 88.889% */
    letter-spacing: 0.18px;
  }
  .tab_bar {
    display: flex;
    justify-content: space-between;
    padding: 28px;
    p {
      margin-bottom: unset;
    }
  }

  .Rejct_Area {
    color: #777;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.14px;
    margin: 20px;
    width: 401px;
    height: 140px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #c9c9c9;
    background: #fff;
    padding: 14px;
  }
  .Rejct_text {
    display: flex;
    justify-content: flex-end;
    margin-right: 18px;
    margin-top: 15px;
    align-items: center;
    button:nth-child(1) {
      padding: 12px;
      text-transform: capitalize;
      color: #8e8e8e;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 52px;
      height: 17.684px;
      flex-shrink: 0;
      margin-right: 5px;
      &:hover {
        background: unset !important;
      }
    }
    button:nth-child(2) {
      padding: 12px 25.3px;
      border-radius: 4px;
      border: 1px solid #29a027;
      background: #29a027;
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }
}
.tab_bars {
  display: flex;
  justify-content: space-between;
  padding: 18px;
  margin: 5px;
  align-items: center;
}

.Like_btn {
  cursor: pointer;
  margin: 2px;
  &:hover {
    background-color: unset !important;
  }
}
.comment_styel {
  margin-left: 15px;
  cursor: pointer;
}
.View_style {
  cursor: pointer;
}

.heading_none1 {
  display: none;
}
@media screen and (max-width: 990px) {
  .heading_none {
    display: none;
  }
  .heading_none1 {
    display: block;
  }
}

//  @media  screen and (max-width: 600px){
// .heading_Mrg{
//   margin-top:16px;
//  }
// }

@media screen and (max-width: 425px) {
  .heading_Mrg {
    padding: 20px 0 0 20px;
  }
  .img_align {
    .name_txt {
      margin-left: 10x;
    }
  }
  .mrgin_left {
    margin-left: 10px;
  }
  .act_btn_tal {
    display: flex;
    justify-content: start;
    margin-left: 10px;
  }
}
.cursor_view {
  cursor: pointer;
}
