.Fade_style{
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 0;
        background: white;
        z-index:  2;
        padding: 15px 20px 10px;
        box-shadow: 0 0 9px #e8eaf5;
        border-radius:5px;
}
.drop_box_close_organizatin{
    
        text-align: end;
        cursor: pointer;
        color: #000;
        border-radius: 50px;
        padding: 4px;
        width: 30px;
        height: 30px;
        display: grid;
        place-content: center;
        margin: 0;
        :hover {
          color: rgb(205, 38, 39);
        }
        background-color: #ffffff;
        border-radius: 4px;
}
.head_model{
        font-size: 17px !important;
        font-Weight: 600 !important;
        margin-bottom: 5px !important ;
}
.phoneBdr{
    border: 1px solid #ccc;
    padding: 14px;
    border-radius: 5px;
  }
  .head_style{
    margin-bottom: -30px !important;
  }

  .save_btn11{
    display: flex;
    justify-content: end;
    margin-top: 10px;
    button{
        padding: 15px 60px;
    }
  }
  .plasma {
    // width: 60%;
    padding: 25px;
    display: inline-block;
    margin-top: -20px;
  }
.close_icon{
 display: flex;
 justify-content: end;
 svg{
  cursor: pointer;
  display: block;
  &:hover {
    color: rgb(205, 38, 39);
  }
 }
}