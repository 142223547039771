@import "../../sass/variables";
@import "../../sass/mixins";

.profile-left {
  position: fixed;
  z-index: 10;
  background-color: white !important;
  border: 2px solid #eee;
  padding: 4px 6px;
  margin: 30px 0 0 12px;
  border-radius: 50px;
}

.new-profile-container {
  h4 {
    color: #ef6c00;
    border-bottom: 1px solid rgb(246, 247, 251);
    padding: 20px;
    text-align: center;
  }
  .green {
    color: green;
    top: 16px;
    position: absolute;
    padding: 0px;
    right: 50px;
  }

  .edit-number {
    background: #f44336 !important;
    color: #fff;
    padding: 0px;
    vertical-align: bottom;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    right: 13px;
    width: auto;
    position: absolute;
    :hover {
      background: #f44336 !important;
    }
    p {
      color: white;
    }
  }

  .makeStyles-paper-16 {
    padding: 0;
  }
  .profile-container-main {
    margin: 0px;
    .header {
      border-radius: 0;

      text-align: center;
      margin-bottom: 5px;
      .title-name {
        color: var(--tal_primary);
        font-weight: 600;
        text-align: center;
        font-size: 25px;
        border-radius: 5px;
      }
      .sub-title {
        display: none;
        color: #757575;
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
    .profile-left {
      display: inline-block;
      padding: 0px;
      .container-left {
        border-radius: 4px;
        max-width: 100%;
        border: 1px solid #ccc;
        border-left: none;
        .user-details-cont {
          .userIcon {
            display: inline-block;
            color: #000;
            // float: left;
            height: 60px;
            width: 60px;
          }
          .user-details {
            .user-name {
              height: auto;
              display: inline-block;
              background: none;
              margin: 0px 0px 0px 10px;
              position: relative;
              top: -7px;
              > span {
                > span {
                  margin: 5px 10px 10px 0px;
                }
              }
            }
          }
        }
        .user-details-cont,
        .personal-links-cont,
        .user-navigation-blk {
          border-left: 0px;
        }
        .user-navigation-blk {
          .user-nav-left {
            width: auto;
          }
        }
      }
    }
    .profile-right {
      .center {
        .avatar-blk {
          background: #3f51b5;
          min-height: 240px;
          position: relative;
          border-radius: 4px;
          .avatar {
            height: 100%;
            width: 100%;

            img {
              margin-top: 30px;
              width: 120px;
              height: 120px;
              border-radius: 50%;
              border: solid 5px white;
            }
          }
          .profileName {
            color: #fff;
            text-align: center;
            margin-top: 12px;
          }
          .changeProfileImg {
            position: absolute;
            background: #fff;
            top: 44%;
            font-size: 35px;
            color: #616161;
            border-radius: 50%;
            padding: 5px;
            right: 32%;
            border: 2px solid #aeaeae;
          }
        }
        .userActivity {
          margin-top: -50px;
          text-align: center;
          position: relative;
          .MuiPaper-rounded {
            padding: 0;
          }
          ul {
            margin: 0 auto;
            padding: 0;
            width: 320px;
            // padding: 0px 20px;

            @media screen and (max-width: 960px) {
              padding: 0 20px;
            }
            li {
              text-align: center;
              display: inline-block;
              width: 150px;
              height: 100px;
              float: left;
              margin: 5px;
              display: grid;
              place-content: center;
              //   width: 40%;
              //   padding: 10px;
              //   margin: 0px 5px 10px 8px;

              @media screen and (max-width: 960px) {
                width: 100%;
                height: 100px;
                float: left;
                margin: 5px 0;
              }

              span {
                color: #fff;
                font-family: "Roboto";
                font-weight: 600;
              }
              p {
                color: #fff;
                font-family: "Roboto";
                font-weight: 600;
                font-size: 11px;
              }
              &:nth-child(1) {
                background: #ff6d8c;
              }
              &:nth-child(2) {
                background: #ffaf6e;
              }
              &:nth-child(3) {
                background: #41d79c;
              }
              &:nth-child(4) {
                background: #40b9ff;
              }
              &:nth-child(5) {
                background: #009688;
              }
              &:nth-child(6) {
                background: #795548;
              }
            }
          }
          .becomeVolunteerBtn {
            background: white;
            color: #f44336;
            border: 1px solid #f44336;

            width: 310px;
            margin-top: 20px;
            height: 50px;

            @media screen and (max-width: 960px) {
              width: 90%;
            }

            span {
              color: #f44336;
            }
          }
          .myProfileBtn {
            background: white;
            color: #40b9ff;
            border: 1px solid #40b9ff;
            width: 310px;
            margin-top: 20px;

            height: 50px;

            @media screen and (max-width: 960px) {
              width: 90%;
            }

            span {
              color: #40b9ff;
            }
          }
          .addOrgBtn {
            background: white;
            color: #96ff40;
            border: 1px solid #96ff40;
            width: 310px;
            margin-top: 20px;

            height: 50px;

            @media screen and (max-width: 960px) {
              width: 90%;
            }

            span {
              color: #96ff40;
            }
          }
        }

        .myOrganizations {
          margin-top: 20px !important;
          padding: 15px;
          width: 100%;
          margin: 0 auto;
          max-width: 308px;

          .side-box {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #f6f7fb;
            margin-bottom: 8px;

            button {
              filter: drop-shadow(0 0 9px #e8eaf5);
              background-color: #ffffff;
              border-radius: 50px;
              min-width: 5px;
              padding: 2px;
              height: 25px;
            }
          }

          @media screen and (max-width: 960px) {
            width: 90%;
          }

          // .MuiButton-root:hover {
          //   text-decoration: none;
          //   background-color: transparent;
          // }

          .add {
            cursor: pointer;
            float: right;
            padding: 1px;
            box-shadow: 0 0 9px #e8eaf5;
            border-radius: 15px;
            min-width: 20px;
            position: relative;
            top: -5px;
            :hover {
              color: var(--tal_primary);
            }
          }

          h6 {
            text-align: start;
            padding-bottom: 10px;
            font-weight: 500;
            border-bottom: 1px solid #f6f7fb;
            margin-bottom: 5px;
          }

          .grid-container {
            display: flex;
            flex-wrap: wrap;
            margin-top: 5px;
          }

          .grid-item {
            text-align: center;
            margin: 3px 3px 5px 2px;
            width: 31%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 0 7px #f5f7ff;
            figure {
              margin: 0 !important;
            }
            img {
              // max-width: 100%;
              // max-height: 100%;
              // padding: 5px;
              max-width: 100%;
              max-height: 100px;
              padding: 5px;
            }
          }
        }
      }
      .right {
        .profile-fields {
          @include input-fields;
          &.gender {
            div {
              padding: 14px;
            }
          }
          &.dob {
            margin: 0;
            background: #fff;
            border: 1px solid #ccc;
            div {
              border-bottom: none;
            }
          }
        }
        .volunteerdesc {
          width: 100%;
          border: 1px solid #ccc;

          font: 400 14px $font-regular;
        }
        .profile-email {
          background: #fff;
          border: 1px solid #ccc;

          padding: 12px 6px;
        }
        .prfDetails {
          .prf-name {
            font-weight: 700;
            color: var(--tal_primary);
            font-size: 25px;
            @media screen and (max-width: 600px) {
              text-align: center;
            }
          }
          .prfLoc {
            font-family: "Roboto";
            font-weight: 400;
            background-image: url("https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/default/geo_fence.svg");
            height: 25px;
            width: auto;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: left top;
            opacity: 0.6;
            padding: 5px 0px 0px 35px;
            background-size: 20%;
            margin-top: 10px;
          }
        }
        .userPointsBlk {
          h6 {
            color: #757575;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 17px;
            @media screen and (max-width: 600px) {
              text-align: center;
            }
          }
          subtitle1 {
            span {
              &:first-child {
                font-weight: 900;
              }
              float: left;
            }
            .userRating {
              color: #424242;
              margin: 1px 0px 0px 5px;
            }
          }
        }
        .userprofileFormBlk {
          border: none !important;
          display: inline-block;
          width: 100%;
          // .MuiSelect-select {
          //   background: #fff;
          //   padding: 13px;
          // }
          .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border-color: #ccc;
          }
          .address {
            padding: 12px;
          }
          .secHeader {
            h5 {
              margin-bottom: 10px;
            }
            @media screen and (max-width: 600px) {
              display: grid;
            }
            p {
              float: left;
              color: var(--tal_primary);
              text-transform: uppercase;
              font-weight: 900;
              @media screen and (max-width: 600px) {
                text-align: center;
              }
            }
            &.volunteerHead {
              p {
                &:nth-child(1) {
                  margin-bottom: 0px;
                  width: 100%;
                }
                &:nth-child(2) {
                  color: #616161;
                  font-size: 15px;
                  font-weight: 300;
                  margin-top: 5px;
                  text-transform: none;
                }
              }
            }
            .secBtn,
            .interestSaveBtn,
            .pwdEditBtn {
              float: right;
              background-color: var(--tal_primary);
              font-weight: 500;
              font-family: "Roboto";
              width: 100px;
            }
            .secDivide {
              float: left;
              width: 100%;
            }
          }
          .PhoneInput {
            border-radius: 3px;
            height: 40px;

            margin: 0px;
            background: #fff;
            border: 1px solid #ccc;

            //padding: 30px 6px;
            input {
              background: transparent;
            }
            .PhoneInputInput {
              height: 40px;
              font-size: 1em;
              border: none;
              &:focus {
                outline: none;
              }
            }
          }
          .secFormLabel {
            color: #757575;
            display: inline-block;
            font-family: "Roboto";
            font-weight: 500;
            margin-bottom: 15px;
            font-size: 14px;
          }
          .secInlineHeader {
            color: var(--tal_primary);
            text-transform: uppercase;
            font-weight: 900;
            margin-bottom: 20px;
          }
          .saveBtn {
            background-color: var(--tal_primary);
            font-weight: 500;
            font-family: "Roboto";
            margin-right: 10px;
            width: 100px;
          }
          .cancelBtn {
            background-color: #757575;
            font-weight: 500;
            font-family: "Roboto";
            width: 100px;
          }
          .secMainDivide {
            float: left;
            width: 100%;
            margin: 20px 0px;
            height: 2px;
            background-color: #9e9e9e;
          }
          .profileInterests {
            border: 1px solid #ccc;
            padding: 30px 25px;
            li {
              display: inline-block;
              margin: 0px 10px 10px 0px;
              .MuiChip-root {
                //this is for the selected interest chip
                border-radius: 2px;
                background: #f44336;
                color: #fff;
                border: 1px solid #f44336;
              }
              .MuiChip-deletable {
                border-radius: 2px;
                background: #eff5fe;
                color: #3081f7;
                border: 1px solid #c1dafc;
                svg {
                  color: #3081f7;
                }
              }
            }
          }
          .noEdit {
            .secFormLabel {
              color: #bdbdbd;
              margin-top: 5px;
            }
            // input {
            //   border: 1px solid #e0e0e0;
            // }
            .saveBtn {
              background-color: var(--tal_primary);
              font-weight: 500;
              font-family: "Roboto";
              margin-right: 10px;
              width: 100px;
            }
            .cancelBtn {
              background-color: #bdbdbd;
            }
          }
          .volunteerDesc {
            width: 100% !important;
            height: 105px !important;
            border: 1px solid #ccc;
            border-radius: 3px;
            background: transparent;
          }
        }
      }
    }
  }
}

.edit-num {
  cursor: pointer;
  position: absolute;
  top: 50%;
  font-size: 16px;
  color: #588158;
  text-decoration: underline;
}
