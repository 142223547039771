@import "../../sass/variables";
@import "../../sass/mixins";

.notifications {
  h5 {
    margin-bottom: 10px;
  }
  hr {
    margin: 0;
  }
  li {
    // padding: 10px 15px;

    div:nth-child(3) {
      color: rgb(205, 38, 39);
      min-width: 20px;
      min-height: 20px;
      border-radius: 50%;
      padding: 10px;
    }
    div:nth-child(3) {
      border: 1px solid var(--tal_primary);
      transition: 0.4s;
    }
    div:nth-child(3):hover {
      color: white;
      background-color: rgb(205, 38, 39);
    }
  }
  // ul:hover {
  //   background-color: #fafafa;
  // }
  @media screen and (max-width: 1170px) {
    padding: 0 10px;
  }

  .container-center {
    background: #fff;
    padding: 15px;
  }

  .hamburger-icon {
    position: fixed !important;
    z-index: 10;
    background-color: white;
    border: 2px solid #eee !important;
    padding: 12px 14px;
    margin: 30px 0 0 12px;
    color: black !important;
    &:hover {
      background-color: var(--tal_primary) !important;
      border: 2px solid #eee;
      color: white !important;
    }
  }
}
