@import "../../../sass/mixins";
@import "../../../sass/variables";
.user_btn {
  text-transform: inherit !important;
  &:hover {
    background-color: unset !important;
  }
  span {
    font-size: 13px !important;
    padding: 10px;
    border-radius: 23px;
  }
}
.btn-render span {
  background-color: #e6e6e6;
  &:hover {
    box-shadow: 0px 0px 5px #666;
  }
}
.active-btn span {
  background-color: rgb(243, 90, 87);
  color: #fff !important;
}
.submit-button {
  background: rgb(243, 90, 87) !important;
  margin: 40px;
  float: right;
  color: white;
  margin-right: 10px;
  width: 120px;
}

.submit-button:hover {
  background: rgb(243, 90, 87) !important;
}
