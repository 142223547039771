.custom_error {
    font: 500 12px "Roboto" !important;
    color: red !important;
    margin-bottom: 8px;
  }
  .bg_normal {
    border: 1px solid #ccc !important;
    outline: unset;
  }
  .bg_green {
    border: 1px solid #ccc !important;
    outline: unset;
  }
  .bg_red {
    border: 1px solid #fd2a2a !important;
    outline: unset;
  }
  .plasma_input {
    border-radius: 4px;
    width: 100%;
    margin: 7px 0 !important;
    border: 1px solid #ccc;
    label {
      background: #fff;
    }
    fieldset {
      border-color: unset;
      border-style: unset;
      border-width: unset;
    }
    input {
      border: unset !important;
      background: unset !important;
    }
  }
  .blood_grp {
    width: 100%;
    label {
      top: 8px;
      background: #fff;
    }
  }
  .date_b {
    label {
      background: #fff;
    }
    div {
      padding-left: 15px;
    }
    div:before {
      border-bottom: none !important;
    }
  }

  .menu_container{
    display: flex;
    min-width: 100%;
    position: relative;
    top: -5px;
    margin-left: 34px;
    @media screen and (max-width: 480px){
        margin: unset;
    }
  }