.sidemenu-container {
  max-width: 300px;
  min-width: 250px;
  // overflow: hidden;
  transition: 0.5s;

  @media screen and (max-width: 960px) {
    position: absolute;
    z-index: 1;
    max-width: 100%;
    box-shadow: 3px 0 5px 0 #0000001a;
    left: 0;
    top: -5px;
    background-color: white;
  }

  .side-Organization {
    h5 {
      border-bottom: 1px solid #0000001a;
      font-size: 16px;
      margin: 10px 15px 0;
      padding: 10px 0;
    }
  }

  .myOrganizations {
    margin-top: 20px !important;
    padding: 15px;
    width: 100%;
    margin: 0 auto;

    .side-box {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f6f7fb;
      margin-bottom: 8px;

      button {
        filter: drop-shadow(0 0 9px #e8eaf5);
        background-color: #ffffff;
        border-radius: 50px;
        min-width: 5px;
        padding: 2px;
        height: 25px;
      }
    }

    .grid-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 5px;
    }

    .grid-item {
      text-align: center;
      margin: 3px 3px 5px 2px;
      width: 31%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 7px #f5f7ff;
      figure {
        margin: 0 !important;
      }
      img {
        // max-width: 100%;
        // max-height: 100%;
        // padding: 5px;
        max-width: 100%;
        max-height: 100px;
        padding: 5px;
      }
    }
    .g-icons {
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }
  }

  .no-pad {
    padding: 0;
  }
  .hr-line {
    width: 90%;
    margin: 5px auto;
    max-height: 0.5px;
    padding: 0;
  }
  .user-details-cont {
    border: none !important;

    padding: 15px !important;
    .user-details {
      .user-name {
        text-align: center !important;
        background-size: 20%;
        height: auto !important;
        margin-bottom: 0;

        .user-profile-image {
          width: 68px !important;
          height: 68px !important;
          border: 2px solid red;
          float: none !important;
          border-radius: 50%;
          box-shadow: 0 0 0px 4px #e9e9e9;
        }
        > p {
          margin-top: 10px;
          width: 100%;
          display: inline-block;
          font-weight: 500;
          text-align: center;
          margin: 4px 0;
          font-size: 12px;
          > span {
            margin: 5px 10px;
            display: block;
            font-weight: 400;
            color: #666;
            cursor: pointer;
            a {
              color: #9e9e9e;
              text-decoration: none;
              font-size: 14px;
              font-weight: normal;
              &:hover {
                color: #cd2527;
              }
            }
          }
        }
      }
      .position {
        width: 100%;
        line-height: 30px;
        text-align: center;
        margin: 0 auto;
        font-size: 15px;
      }
    }
    .user-ratings-blk {
      margin-bottom: 0px;
      margin-top: 6px;
      text-align: center;
    }
  }
  .saved-req-blk {
    padding: 5px 16px 15px !important;
    border: none !important;
    .saved-req-links {
      margin-bottom: 0;
      font: 500 12px "Roboto";
      text-align: left;

      svg {
        color: var(--tal_primary);
        font-size: 30px;
      }
      > a {
        color: #424242;
        text-decoration: none;
        padding: 15px;
        display: inline-block;
        margin-top: 10px;
        font-size: 17px;
        font-weight: bold;
      }
    }
  }
  .personal-links-cont {
    padding: 5px 15px !important;
    border: none !important;
    ul {
      padding: 0px;
      margin: 0px;
      li {
        display: flex;
        width: 100%;
        text-align: left;
        padding: 0px 0px;
        justify-content: space-between;
        font-weight: 300;
        font-size: 12px;
        margin: 10px 0px;
        cursor: pointer;

        span:nth-child(2) {
          text-align: end;
          min-width: 15%;
          // box-shadow: 0 0 8px #f6f8fe;
          border-radius: 4px;
          padding: 2px 4px;
          background: rgb(246, 247, 251);
        }
      }
    }
  }
  .user-navigation-blk {
    border: none !important;
    background: #fff;
    .user-nav-left {
      background: #fff;
      padding: 30px 15px 30px 15px;
      width: 100%;
    }
  }
  .profile-block {
    hr {
      border-top: 1px solid #f6f7fb;
    }
  }
  .admin-block {
    margin: 21px 0;
    li {
      border-bottom: 1px solid #f6f7fb;
    }

    .admin-nav-options {
      padding: 0;
      .talradio-nav-options {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .MuiListItemIcon-root {
        min-width: 30px;
      }
      span {
        font: 300 12px "Roboto";
      }
    }
  }
  .vol-block {
    margin-top: 23px;
    margin-bottom: 11px;
    padding: 15px;

    text-align: start;
    // height: 88px;
    min-height: 120px;

    p {
      font: 400 11px "Roboto";
      margin: 4px;
      line-height: 20px;
    }
    button {
      background: transparent !important;
      font: 400 10px "Roboto";
      float: right;
      color: #2196f3 !important;
      text-transform: none;
    }
  }
  .store-block {
    margin: 23px 0 0 0;
    text-align: start;
    padding: 15px;
    border-top: 1px solid #000;
    // filter: drop-shadow(0 0 9px #e8eaf5);
    // background-color: #ffffff;
    // border-radius: 4px;

    .scan-img {
      display: grid;
      place-content: center;
    }

    hr {
      margin: 10px 0;
    }
    p {
      font: 600 12px "Roboto";
      margin: 0;
    }
    div {
      width: 100%;
      img {
        margin-left: -7px;
        width: 150px;
        margin-bottom: 10px;
      }
    }

    .scan {
      color: var(--tal_primary);
      font-weight: 400;
      text-align: center;
      font-size: 17px;
      padding: 5px;
      background: rgb(246, 247, 251);

      svg {
        color: rgb(40, 62, 74);
        margin: 0 5px;
      }
    }
  }
}
