.main_box {
  background-color: #283e4a;
  padding: 70px 0;
  margin-top: -25px;
  @media screen and (max-width: 599px) {
    padding: 30px 0;
  }

  h2 {
    color: #fff;
    font-size: 42px;
  }
  p {
    color: #fff;
    font-size: 24px;
  }
}

.Admin_statusBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -10px;
}
.mid_row {
  p {
    color: #353535 !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.Srch_Export {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 5px;
  @media screen and (max-width: 599px) {
    margin-top: 12px !important;
  }
}

.Btn_newLed {
  font-size: 10px;

  span {
    background: #cf3b3a;
    color: white;
    width: 117.198px;
    height: 42px;
    padding: 10px;
    margin: 10px 0 !important;
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    text-transform: capitalize;
  }
  &:hover {
    background: unset !important;
  }
}
.Btn_new {
  span {
    background: #eee;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    width: 117.198px;
    height: 42px;
    padding: 10px;
    margin: 10px 0 !important;
    border-radius: 5px;
    text-transform: capitalize;
  }
  &:hover {
    background: unset !important;
  }
}
.btn_Export {
  border-radius: 8px;
  width: 280px;
  border: 1px solid #ccc;
  @media screen and (max-width: 599px) {
    width: 100%;
  }
}
.Export_btn {
  img {
    padding-right: 8px;
  }
  span {
    color: #cf3b3a;
    border: 1px solid #cf3b3a;
    padding: 12px 15px;
    margin: 10px 0 !important;
    border-radius: 5px;
  }
  &:hover {
    background: unset !important;
  }
}
.act_btn_tal {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: end;
}
.Approve_btn {
  width: 150px;
  &:hover {
    background-color: unset !important;
  }
  span {
    display: flex;
    gap: 5px;
    color: #11d30a;
    background: #f7fff6;
    border: 1px solid #11d30a;
    padding: 5px 20px;
    border-radius: 2px;
  }
}
.Rejct_btn {
  width: 150px;
  &:hover {
    background-color: unset !important;
  }
  span {
    display: flex;
    gap: 5px;
    color: var(--tal_primary);
    background: #fff8f8;
    border: 1px solid var(--tal_primary);
    padding: 5px 20px;
    border-radius: 2px;
  }
}
.Edit_btn {
  display: flex;
  align-items: center;
  border-radius: 5px;
  button {
    &:hover {
      background: unset !important;
    }
  }
}
.name_pro_box {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  @media screen and (max-width: 599px) {
    gap: 0;
  }
  button {
    &:hover {
      background-color: unset !important;
    }
  }
  span {
    background-color: #eee;
    color: #606060;
    padding: 8px 10px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 12px;
  }
}
.name_align {
  display: flex;
  background-color: #fff;
  box-shadow: 5px 10px #fff;
  padding: 15px;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 25px;
  box-shadow: 0 2px 5px #eee;
  .img_align {
    display: flex;
    gap: 10px;
    .name_txt {
      .name_title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      p {
        margin-bottom: unset;
        span {
          font-weight: 600;
        }
      }
    }
  }
}
.main_tab {
  header {
    background-color: #fff;
    color: #ddd;
    width: -webkit-max-content;
    width: max-content;
    margin: -18px auto 0;
    border-radius: 40px;
    position: relative;
    z-index: 1;
  }
}
.head_style {
  padding: 24px 0px;
}

.View_icon {
  display: flex;
  // justify-content: end;
  gap: 10px;
  display: flex;
  justify-content: start;

  p {
    text-transform: initial;
  }
  button {
    &:hover {
      background: unset !important;
    }
  }
}
.Veiw_Profile {
  button {
    background: #ffd7d7;
    color: #cf3b3a;
    //width: 130%;
    height: 36px;
    text-transform: inherit;
    white-space: nowrap;
    &:hover {
      background: #ffd7d7;
      color: #cf3b3a;
    }
  }
}
.icon_btns {
  display: flex;
  justify-content: start;
  align-items: center;
  //margin-left: -15px;
  margin-bottom: 16px;
  gap: 15px;
}

.Edit_icon {
  background-color: #eeeeee;
  border: 1px solid #959595;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  width: 24px;
  text-align: center;
  img {
    margin-left: 1px;
  }
}
.Edit_icon1 {
  background-color: #eeeeee;
  border: 1px solid #959595;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  width: 24px;
  text-align: center;
  margin-left: 40px;
  img {
    margin-left: 1px;
  }
}
.Edit_icon2 {
  background-color: #eeeeee;
  border: 1px solid #959595;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  width: 24px;
  text-align: center;
  margin-left: 30px;
  img {
    margin-left: 1px;
  }
}

.Sort_slelct {
  display: flex;
  gap: 10px;
  align-items: center;
}
.Select_btn {
  margin-right: 20px;
  width: 30%;
  height: 55px;
}
.Veiw_Profile_radius > div > div {
  .MuiDrawer-paperAnchorRight {
    border-top-left-radius: 50px !important;
  }
}
.box_model {
  padding: 20px;
}
.tab_bar {
  display: flex;
  justify-content: end;
}
.Rejct_text {
  display: flex;
  justify-content: end;
  gap: 15px;
  margin-top: 27px;
}
.profile_imgs {
  width: 17.212px;
  height: 16.798px;
  flex-shrink: 0;
  margin-left: 4px;
  margin-bottom: 3px;
}
.Approv_btn {
  border-radius: 5px;
  border: 1px solid #29a027;
  Button {
    background: #29a027;
    color: #fff;
    width: 108.9px;
    height: 42px;

    &:hover {
      background: #29a027 !important;
    }
  }
}
.reject_btn {
  border-radius: 5px;
  border: 1px solid #f35a57;
  cursor: pointer;

  Button {
    background: #f35a57;
    color: #fff;
    width: 108.9px;
    height: 42px;

    &:hover {
      background: #f35a57 !important;
    }
  }
}
.cancle_btn {
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  Button {
    background: #ccc;
    width: 108.9px;
    height: 42px;

    &:hover {
      background: #ccc !important;
    }
  }
}
.Rejct_Area {
  color: #777;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.14px;
  margin: 10px;
  width: 390px;
  height: 145px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #c9c9c9;
  background: #fff;
  padding: 14px;
}
.No_leaders {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
