@import "../../sass/variables";
@import "../../sass/mixins";

.footer-container {
  height: auto;
  border-top: 1px solid #eee;
  margin-top: 20px;
  background: #283e4a;
  padding: 15px 0;

  p {
    font: 500 12px $font-regular;
    margin: 6px 0;

    color: #fff;
    // width: 50%;
    // display: inline-block;
  }

  .left,
  .right {
    text-align: center;
  }
  .left {
    @media (min-width: 960px) {
      text-align: left;
    }
  }

  .right {
    @media (min-width: 960px) {
      text-align: right;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      color: #596eff;
    }
  }

  @media (max-width: 600px) {
    .left,
    .right {
      text-align: center;
    }
  }
}
