@import "../../sass/variables";
@import "../../sass/mixins";

.comments-container {
  width: 100% !important;
  padding: 0 15px;

  .more-btn {
    background-color: rgb(223, 223, 223) !important;
    height: 40px;
    width: 40px;
    margin: 10px;
  }

  h4 {
    font-weight: 400;
 
    width: 100%;
    .close-icon {
      float: right;
      border: 1px solid #eee;
      border-radius: 50%;
      padding: 10px;
      background-color: #aaa;
      color: #fff;
    }
  }
  .comment-section-block {
    width: 100%;
    margin: 0px auto;

    .recent-comments {
      width: 100%;
      margin: 10px 0;
      display: flex;
      .comment-avatar {
        margin-top: 5px;
        display: inline-block;
        width: 40px !important;
        height: 40px !important;
        vertical-align: text-bottom !important;
        .comment-avatar {
          width: 100% !important;
        }
      }
      .comments-main-details {
        display: inline-block;
        padding: 10px;
        width: 100%;
        padding-left: 20px;
        margin-left: 5px;
        background: #eee;
        border-radius: 25px;
        .comment-details {
          .commentor-name {
            font: 600 14px $font-regular;
            color: #333;
          }
          .comment-time {
            font: 500 12px $font-regular;
            color: #aaa;
          }
        }
      }
    }
  }
  .comment-textfield-container {
    width: 100%;
    margin: 5px 0 20px 10px;
    display: flex;
    div {
      &:after,
      &::before {
        border-bottom: none !important;
        outline: none !important;
      }
    }
    .comment-avatar {
      // display: inline-block;
      margin-right: 10px;
    }
    .comments-field {
      width: 87%;
      background: #eee;
      border: none !important;
      border-radius: 25px;
      padding: 5px 10px;
    }
    .send-icon {
      display: none;
      cursor: pointer;
      color: rgb(97, 97, 253);
    }
  }
  .not-logged-text {
    // padding:0 15px;
    text-align: left;
    color: var(--tal_primary);
    padding: 10px;
  }

  .comment-btn {
    cursor: pointer;
    text-align: right;
    font-size: 16px;
  }

  @media screen and (max-width: 600px) {
    .not-logged-text,
    .comment-btn {
      text-align: center;
    }
  }
}
.comments-edit-field {
  width: 100% !important;

  div {
    &:before {
      border-bottom: none !important;
    }
    &:hover {
      border-bottom: none !important;
    }
    &:after {
      border-bottom: none !important;
    }
  }
}

.my-list {
  &:hover {
    background: none !important;
  }
}
