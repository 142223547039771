@import "../../sass/variables";
@import "../../sass/mixins";

.faq-container {
  .faq-body {
    width: 100%;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    .faq-individual {
      height: 100%;
      padding: 15px;
      h4 {
        font: bold 1.1em "Roboto";
        text-transform: uppercase;
        margin-bottom: 30px;
      }
    }
  }
  .faq-title {
    text-align: center;
    margin: 10px 0;
    padding: 10px 0;
    h2 {
      font: 900 3em "Roboto";
    }
    p {
      font: 700 1em $font-regular;
      text-transform: uppercase;
      letter-spacing: 4px;
      hr.hor-line {
        width: 20px;
        display: inline-block;
        margin: 6px 21px;
        border: 2px solid var(--tal_primary);
      }
    }
  }
  .faq-footer {
    width: 100%;
    margin: 20px 0;
    text-align: center;
    padding: 15px;
    h1 {
      font: 800 26px $font-regular;
    }
    button {
      @include submitButton;
      width: 175px;
    }
  }
}
