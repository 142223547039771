.custom_error {
    font: 500 12px "Roboto" !important;
    color: red;
    margin-top: 8px;
  }
  .bg_normal {
    border: 1px solid #ccc !important;
    outline: unset;
  }
  .bg_green {
    border: 1px solid #ccc !important;
    outline: unset;
  }
  .bg_red {
    border: 1px solid #fd2a2a !important;
    outline: unset;
  }
  .plasma_input {
    border-radius: 4px;
    label {
        background: #fff;
      }
    fieldset {
      border-color: unset;
      border-style: unset;
      border-width: unset;
    }
    button {
      margin-top: -12px;
    }
  }
  .blood_grp {
    width: 100%;
    label {
      top: 8px;
      background: #fff;
    }
  }
  .date_b {
    label {
      background: #fff;
    }
    div {
      padding-left: 15px;
    }
    div:before {
      border-bottom: none !important;
    }
  }
  .user_btn {
    &:hover {
      background-color: unset !important;
    }
    span {
      font-size: 11px !important;
      padding: 10px;
      border-radius: 23px;
    }
  }
  .btn_render span {
    background-color: #e6e6e6;
    &:hover {
      box-shadow: 0px 0px 5px #666;
    }
  }
  .active_btn span {
    background-color: rgb(243, 90, 87);
    color: #fff !important;
  }
 