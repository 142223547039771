.Fade_style {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  padding: 15px 20px 10px;
  border-radius: 5px;
  border-bottom: 1px solid #cccc;
  > div {
    svg[focusable="false"] {
      cursor: pointer;
    }
  }
}
.drop_box_close_organizatin {
  text-align: end;
  cursor: pointer;
  color: rgb(205, 38, 39);
  background-color: #ffd7d7;
  padding: 4px;
  display: grid;
  place-content: center;
  margin: 0;
  border-radius: 4px;
  width: max-content;
  span {
    color: rgb(205, 38, 39);
  }
  :hover {
    color: rgb(205, 38, 39);
    background-color: #ffd7d7;
  }
  button {
    background: unset !important;
    text-transform: inherit;
  }
}
.head_model {
  font-size: 17px !important;
  font-weight: 600 !important;
  margin-bottom: 5px !important ;
}
.phoneBdr {
  border: 1px solid #ccc;
  padding: 14px;
  border-radius: 5px;
}
.head_style {
  margin-bottom: -30px !important;
}

.save_btn11 {
  display: flex;
  justify-content: end;
  margin-top: 10px;
  button {
    padding: 15px 60px;
  }
}
.plasma {
  width: 100%;
  padding: 25px;
  display: inline-block;
  margin-top: -20px;
  position: relative;
  @media screen and (max-width: 599px) {
    padding-bottom: 95px;
  }
}
.close_icon {
  display: flex;
  justify-content: end;
  svg {
    cursor: pointer;
    display: block;
    &:hover {
      color: rgb(205, 38, 39);
    }
  }
}
.Fad_style {
  display: flex;
  width: 100%;
  // margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 599px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.Fad_style_edit {
  flex-direction: column;
  display: flex;
  width: 100%;
  .user_profile_le {
    @media screen and (max-width: 599px) {
      flex-direction: column;
      align-items: flex-start;
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      > div {
        width: 47%;
        label {
          margin-bottom: 0;
          div {
            width: 100%;
            background: #fff;
            border: none;
          }
        }
        > div {
          width: 100%;

          > div {
            background: #fff;
            width: 100%;
          }
        }
      }
    }
  }
}
.name_title {
  @extend .head_model;
  p {
    color: #eee;
  }
}
.name_text {
  padding-left: 15px !important;
  margin-top: 5px;
}
.text_mrg {
  display: flex;
}
.text_mrgs {
  @extend .text_mrg;
  justify-content: space-between;
  position: fixed;
  width: 55%;
  bottom: 0;
  background: #fff;
}
.text_mrgn {
  margin-right: 20px;
}
.text_flex {
  @extend .text_mrg;
  margin: 5px;
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}
.text_flex_edit {
  @extend .text_mrg;
  margin: 5px;
  gap: 12px;
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}
.profile_mrg {
  margin: 20px 0px !important;
}
.profile_p {
  font-size: 10px;
  color: rgb(95, 93, 93);
  margin: 11px;
  span {
    font-size: 5px;
    color: #ccc;
  }
}
.profile_img {
  height: 15px;
  width: 15px;
  margin-right: 9px;
}

.profile_border {
  margin: 20px 0px !important;
  border-left: 2px solid #ff8080 !important;
  padding-left: 10px;
}
.fade {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.position_mrg {
  position: relative;
}
.reject_color {
  span {
    width: 121px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #cf3b3a;
    color: #cf3b3a;
    background: #fff8f8;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}
.Approve_clr {
  &:hover {
    background-color: unset !important;
  }
}
.Approve_color {
  span {
    width: 121px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 4px;
    color: #29a027;
    border: 1px solid #29a027;
    background: #f7fff6;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}
.edit_profile {
  span {
    display: flex;
    width: 77px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    background: #f0f0f0;
    text-transform: capitalize;
  }
  &:hover {
    background-color: unset !important;
  }
}
.save_Button {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}
.Approves_color {
  span {
    width: 121px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 4px;
    color: #fff;
    border: 1px solid #f35a57;
    background: #f35a57;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}
.rejects_color {
  span {
    width: 121px;
    height: 38px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #f8f8f8;
    color: #2a2a2a;
    background: #f8f8f8;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }
}
.profile_lenth {
  width: 17.212px;
  height: 16.798px;
  flex-shrink: 0;
  margin-left: 4px;
  margin-bottom: 3px;
}
.location_aglin {
  width: 197px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  background: #f0f0f0;
  margin: 6px 4px;
}
.chip_design {
  button {
    cursor: unset;
    &:hover {
      background-color: unset !important;
    }
  }
  span {
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #f0f0f0;
    text-transform: capitalize;
    color: #000;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
  }
}
.phone_input {
  display: flex;
  align-items: center;
  width: 170px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background: #fff;
  > div {
    margin-left: 5px !important;
  }
}
.edit_text_fi {
  > div {
    background-color: #fff;
    border: none;
  }
}
.bg_normal {
  border: 1px solid #ccc;
  outline: unset;
}
.edit_reject_Short_btn {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 47%;
  background: #fff;
  @media screen and (max-width: 899px) {
    width: 80%;
  }
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
}
.user_profile_le {
  display: flex;
  // justify-content: space-between;
  //width: 100%;
  align-items: center;
  @media screen and (max-width: 599px) {
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 0;
  }
}
