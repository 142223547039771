._empower_box_grid{
    margin-top: -120px;
    @media screen and (max-width:960px){
        justify-content: center;
        margin-top: 20px;
    }
}
.trophy_side{
    margin-left: 124px;
    margin-top: 15px
}

._empower_box_in{
    @media screen and (max-width:960px){
        
        margin-bottom: 20px !important;
    }
    ._make_img{
        width: 100%;
        img{
            width: 100%;
        }
    }
    ._empower_ele{
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        gap: 20px;
        padding:30px;
        // box-shadow: 0px 0px 9px 0px rgb(201, 201, 201); 
        border-radius:12px;
        margin: 0 20px;
        background: white;
        ._img_card{
            height: 130px;
            width: 130px;
            display:flex;
            align-items: center;
            justify-content: center;
        }
        ._empower_img{
            @extend ._img_card;
            border: 3px solid #FF273A;
            border-radius: 50%;
        }
        ._inspiring_img{
            @extend ._img_card;
            border: 3px solid #277DFF;
            border-radius:26px;
        }
        ._connect_img{
            @extend ._img_card;
            border: 3px solid #78CA51;
            border-radius: 50%;
        }
        // p{
        //     text-align: left;
        //     font-size: 16px;
        // }
    }
}
.main_head{
    background: #283E4A;
    margin-top:-28px;
}
.txt_style{
    font-weight: 600;
    font-size: 30px;
}
.add_fund_btn{
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      background: var(--tal_primary);
      color:white;  
      text-transform: inherit;

        &:hover{
            background: var(--tal_primary)
         }
      padding: 10px 0;
      margin: 10px 0 !important;
      &:last-child {
        width: 100% !important;
      }
      &:first-child {
        margin-right: 10px 0px !important;
      }
    }
  }
  
  .banner_titles{
    align-self: center;
    margin: 40px;
    h1{
        color:#fff;
        font-size: 3.1em;
        font-weight: 600;
    }
    p{
        color:#fff;
        font-size: 1.7em;
        font-weight: 300;
    }
}
