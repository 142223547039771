.img_align {
  display: flex;
  gap: 30px;
}
.img_chge {
  img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }
}
.Fade_style {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  padding: 15px 20px 10px;
  box-shadow: 0 0 9px #e8eaf5;
  border-radius: 5px;
}
.link_btn {
  button {
    background: #ffd7d7;
    color: #cf3b3a;
    padding: 8px 10px;
    width: max-content;
    border-radius: 4px;
    text-transform: capitalize;
    margin-right: 0 !important;
    span {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    &:hover {
      background-color: #ffd7d7 !important;
    }
  }
  button {
    float: right;
    margin-right: 50px;
  }
}

.name_txt {
  .name_title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: unset;
    span {
      font-weight: 500;
    }
  }
}
.close_btn {
  margin-left: -50px;
  margin-top: 15px;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 5;
}
.tabs_bar1 {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  position: relative;
  > header {
    width: 100%;
    position: relative;
    z-index: 1;
    > div {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }
}
.cards_style {
  display: flex;
  margin: 12px;
}
.waring_text {
  background: #eee;
  margin: 15px;
  padding: 15px;
  border-radius: 10px;
  color: #060606;
}
.Rejct_Area {
  color: #777;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.14px;
  margin: 20px;
  width: 600px;
  height: 140px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #c9c9c9;
  background: #fff;
  padding: 14px;
}
.dr_margin {
  margin-top: 10px !important;
}
.pop_like_com {
  padding: 30px;
  margin-top: 10px !important;
}
