
.withdrawal {
  font-weight: bold;
  text-align:center;
  padding:10px;
  background: #eff1f9;
  border-radius: 5px;
}

.withdrawal-selected {
  border-radius: 5px;
  font-weight: bold;
  text-align:center;
  background: #eff1f9;
  padding:10px;
  border:1px solid #ef6c00;
}


.bai{
  h2{
    color: #ef6c00;
    font-size: 26px;
    border-bottom: 1px solid rgb(246, 247, 251);
    padding-bottom: 10px;
  }
}