.file-edit-container {
  width: 100%;
}
.file-drop-box {
  filter: drop-shadow(0 0 9px #e8eaf5);
  background-color: #ffffff;
  border-radius: 4px;
  margin: 5px 15%;
  padding: 5px;
  min-height: 900px;
}
.close-btn-text {
  text-transform: capitalize;
  font-weight: 100;
}
.file-upload-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid rgb(246, 247, 251);
}
.file-upload-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.dropzone {
  text-align: center;
  // border: 1px dashed #8c8c8c;
  background-color: #f8f8f8;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 10px 0;
  // padding: 20px;
}
.contact-types {
  padding: 5px;
  margin: 5px;
}
.file-cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;
}
