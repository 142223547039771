

.add_update_btn 
{
    background-color: #f35a57 !important;
    border-radius: 5px !important;
    color: #ffffff !important;
    padding:10px !important;
  
  }
  .du_app{
    margin: 15px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }