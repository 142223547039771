@import "../../sass/variables";
@import "../../sass/mixins";

.public-profile-container {
  margin-top: -40px;
  .tabname {
    padding: 0 20px;
    @media screen and (max-width: 480px) {
      padding: 0 5px;
    }
  }
  .white {
    position: absolute;
    background: white;
    height: 441px;
    width: 100%;
    top: 80px;

    @media screen and (max-width: 960px) {
      height: 460px;
      top: 73px;
    }
    @media screen and (max-width: 600px) {
      height: 500px;
    }
  }

  // .MuiButton-root:hover {
  //   text-decoration: none;
  //   background-color: var(--tal_primary);
  // }
  .public-cause-btn {
    background: var(--tal_primary);
    color: #fff;
    min-width: 174px;
    max-height: 40px;
    position: absolute;
    right: 20px;
    top: 60px;
  }
  .borderBottom-nav {
    border-top: 1px solid #d6d6d6;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .MuiListItem-root {
      width: auto;
    }

    @media screen and (max-width: 600px) {
      border-bottom: none;
    }
  }
  .options {
    padding-bottom: 0;
  }

  .public-main {
    .public-cover-page {
      padding: 40px 0 0 0;
      margin-bottom: 20px;
      position: relative;
      @media screen and (max-width: 640px) {
        margin-bottom: 70px;
      }
    }
    .updateBannerBlk {
      position: absolute;
      right: 10px;
      color: #000;
      cursor: pointer;
      background: white;
      border-radius: 50px;
      padding: 5px;
      box-shadow: 0 1px 2px black;
      height: 32px;
      width: 32px;
      margin-top: 10px;
    }
   .coverpic {
    img{
      height: 400px;
    }
      background: #e9eaec;
      overflow: hidden;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      @media screen and (max-width: 600px) {
        max-height: 165px;
      }
    }
    .public-card-header {
      display: block !important;
      position: relative;
      margin-top: -115px;
      padding: 0;
      .MuiCardHeader-avatar {
        width: 100%;
      }
      .profile-pic-container {
        position: relative;
        text-align: center;
        // margin-bottom: 25px;
        h2 {
          font-size: 1.5rem;
          padding-top: 10px;
        }
        .profile-main {
          width: 150px;
          position: relative;
          margin: 0 auto;
        }

        .profileCamIcon {
          position: absolute;
          right: 23px;
          top: 100px;
          text-align: center;
          cursor: pointer;
          background: white;
          border-radius: 50px;
          padding: 5px;
          box-shadow: 0 1px 2px black;
          height: 30px;
          width: 32px;
        }
        .profile-pic {
          object-fit: cover;
          border-radius: 50%;
          border: 5px solid #fff;
          box-shadow: 0 1px 2px black;
          width: 130px;
          height: 130px;
        }
      }
      .MuiCardHeader-content {
        h2 {
          font: 600 20px $font-regular;
          margin: 0;
          color: #fff;
        }
        p {
          font: 500 14px $font-regular;
          margin: 4px;
        }
      }

      .public-page-navbar {
        padding: 8px 0;
        display: flex;

        .MuiListItem-gutters {
          padding-right: 10px;
        }

        .options {
          .share-cont {
            position: relative;
            &:hover {
              background: transparent;
            }
            button {
              color: #000;
              border-radius: 5px !important;
              &:hover {
                background: transparent;
              }
            }
          }
        }
      }
    }
  }

  .postTitle-text {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    padding: 5px 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .postDesc {
    font-size: 14px;
    position: relative;
    display: inline-block;
    font-weight: 400;
    line-height: 18.5px;
    &::first-letter {
      text-transform: uppercase;
    }
  }
   .postDescs {
    font-size: 14px;
    padding: 0 20px 0 0; 
    position: relative;
    display: inline-block;
    font-weight: 400;
    line-height: 18.5px;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  .share-btn {
    color: #1771e6;
    width: fit-content;
    padding: 0;
  }
  .public-header-nav {
    width: 80%;
    margin: 10px auto;
    .MuiListItem-gutters {
      display: inline;
    }
  }

  .public-page-body {
    width: 100%;
    .textBlk,
    .mediaBlk {
      background: none;
    }

    .no-user-text {
      text-align: center;
      font-style: italic;
      margin: 20px 0;
      background: #fff;
      padding: 50px 0;
    }
    .public-body-grid {
      .public-grid-left {
        .about-details {
          padding: 10px;
          margin-bottom: 10px;
        }
        h2 {
          font: 600 18px $font-regular;
          margin: 15px 0;
        }
        p {
          font: 400 12px $font-regular;
          margin: 15px 0;
          svg {
            font-size: 24px;
          }
        }
      }
      .public-grid-right {
        .fa-hands-heart {
          width: 40px;
          margin-right: 10px;
        }
        .postBy {
          text-transform: none;
          &::first-letter {
            text-transform: uppercase;
          }
          img {
            position: absolute;
            height: 40px;
          }
        }
        .postCardPaper {
          z-index: 1;
          border: none !important;
          border-radius: 0;
          margin-top: 0;
          .MuiPaper-rounded {
            box-shadow: none !important;
            border-radius: 0;
            overflow: visible;
          }
        }
        .MuiCardHeader-content {
          text-align: start;
        }

        .post-card-content {
          text-align: start;
          .remaining-amt {
            text-align: start;
          }

          .postTitle {
            margin-top: 8px;
            .postTitle-fav {
              width: 5%;
              float: right;
            }
          }
          .postSubTitle {
            font: 500 12px $font-regular;
            text-transform: capitalize;
          }
        }

        .requests-list {
          background: #fff;
          height: 120px;
        }

        .postCard {
          text-align: left;
          .user-profile-img {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
          .postBy {
            text-transform: none;
            &::first-letter {
              text-transform: uppercase;
            }
            .MuiTypography-body2 {
              font-weight: bold;
            }
            .MuiCardHeader-subheader {
              font-weight: normal;
            }
            .MuiAvatar-circle {
              background: transparent;
              width: 47px;
              height: 47px;
            }
          }
          .postTitle,
          .postSubTitle {
            margin-bottom: 0px;
            font-weight: bold;
          }
          .postSubTitle {
            font-weight: normal;
          }
          .postImg {
            background-color: #e3e3e3;
            max-width: 100%;
            max-height: 600px;
            display: grid;
            place-content: center;
            min-height: 250px;
            figure {
              margin: 0;
              padding: 0;
              text-align: center;
              img {
                max-width: 100%;
                display: block;
                max-height: 600px;
              }
            }
          }
          .post-card-content {
            padding: 15px 16px 0;
          }
          .prgBar {
            border-radius: 10px;
            height: 8px;
            margin: 10px 0;
          }
          .postDividerIn {
            margin: 10px 0px 0px;
          }
          .remaining-amt {
            text-align: start;
            color: #000;
            font-size: 14px;
            margin-bottom: 0;
            font-weight: bold;
          }
          .post-view-more {
            display: none;
            float: right;
            color: rgb(64, 64, 182);
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 34px;
            background: #fff;

            &::before {
              content: "... \00a0 \00a0 \00a0 ";
              color: #000;
              text-decoration: none;
            }
          }
          .card-actions {
            padding: 5px;
          }
          .post-actions-left {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .action-text {
              font-size: 12px;
            }
            button {
              padding: 12px 6px;
              span {
                color: #000;
              }
            }
          }
          .post-action-right {
            text-align: right;
            width: 40%;
            .donateNowBtn {
              @include submitButton;
              width: 100%;
              margin: 0;
            }
          }
        }
      }
    }
    &.about-option {
      .about-section {
        background: #fff;

        position: relative;

        h2 {
          font-weight: 600;
          font-size: 20px;
          text-align: center;
          border-bottom: 1px solid #eee;
          padding: 10px;
        }
        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 25px;
        }
      }
    }
    &.photos-option {
      .photos-section {
        background: #fff;

        position: relative;

        h2 {
          padding: 10px;
          font-weight: 600;
          font-size: 20px;
          text-align: center;
          border-bottom: 1px solid #eee;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
        .images-container {
          display: inline-block;
          width: 29%;
          margin-right: 10px;
        }
      }
    }
    &.videos-option {
      .videos-section {
        background-color: white;
        position: relative;

        button {
          background-color: var(--tal_primary);
          color: white;
          margin: 5px;
          float: right;
        }

        h2 {
          padding: 10px;
          color: var(--tal_primary);
          font-weight: 600;
          font-size: 20px;
          text-align: center;
          border-bottom: 1px solid #eee;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.public-grid-news {
  max-width: 20%;
  .no-pad {
    padding: 0;
  }
  .promotion {
    border-radius: 5px;
    img {
      width: 100%;
    }
  }
  .orgListBlk {
    margin-top: 50px;
    .orgListInner {
      display: inline-block;
      text-align: left;
      .orgList-title {
        margin: 0px 0px 20px 0px;
        font-weight: bold;
        background-image: url("https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/material3/ic_location_city_48px.svg");
        background-repeat: no-repeat;
        height: 48px;
        background-position: left top;
        padding: 5px 0px 0px 40px;
        background-size: 10%;
        font-size: 17px;
        margin-bottom: 0px;
      }
      ul {
        padding: 0px;
        margin: 0px;
        li {
          display: inline-block;
          width: auto;
          text-align: left;
          padding: 5px;
          img {
            height: 59px;
          }
          li:last-child img {
            height: 75px;
          }
        }
      }
    }
  }
  .latestNewsBlk {
    margin-top: 20px;
    padding: 16px 0;
    .newsList {
      text-align: left;
      .newsList-title {
        color: #424242;
        font-weight: bold;
        background-image: url("https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/material3/ic_subject_48px.svg");
        height: 48px;
        background-repeat: no-repeat;
        background-position: left top;
        background-size: 10%;
        padding: 0px 40px;
        font-size: 20px;
        margin: 0px 0px 0px 0px;
      }
      ul {
        margin: 0px;
        padding: 0px;
        li {
          padding: 0px 0px 10px 0px;
          margin: 0px 0px 0px 20px;
          font: 400 12px $font-regular;
          color: #424242;
          font-size: 12px;
          line-height: 25px;
          list-style: none;
        }
        li::before {
          content: "•";
          color: #2196f3;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
      .newsListActions {
        float: right;
      }
    }
  }
  .topReqListBlk {
    margin-top: 20px;
    padding: 16px 0;
    .topReqList {
      text-align: left;
      .topReqListTitle {
        background-image: url("https://s3.amazonaws.com/assets.mockflow.com/app/wireframepro/svg/material3/ic_beenhere_48px.svg");
        background-repeat: no-repeat;

        font-size: 14px;
        font-weight: bold;
        padding: 0px 0px 7px 40px;
        background-size: 10%;
        height: 50px;
      }
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        padding: 20px 0px 20px;
        border-bottom: 1px solid #e0e0e0;
        img {
          height: 40px;
          float: left;
          padding-right: 10px;
        }
        span {
          color: #0067ba;
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .borderBottom-nav {
    border-bottom: none;
    position: relative;
    top: 40px;

    .MuiListItem-gutters {
      padding-left: 0;
    }
  }
  .public-cause-btn {
    left: 0;
    top: 170px !important;
    position: absolute !important;
  }

  .share-cont {
    position: absolute;
    top: -45px;
    right: 0;
    @media screen and (max-width: 480px) {
      top: unset;
      right: -12px;
    }
    .MuiListItem-gutters {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .share-option {
    top: 50px !important;
    @media screen and (max-width: 480px) {
      top: -20px !important;
    }
  }

  .public-card-header {
    .MuiCardHeader-action {
      position: absolute;
      bottom: -18px;
      right: 0;
      left: 100px;
    }
  }
  .public-page-body {
    margin-top: 35px;
    .post-actions-left {
      min-width: 70% !important;
    }
    .post-action-right {
      .donateNowBtn {
        font-size: 8px !important;
      }
    }
  }
  .share-option {
    right: 12px !important;
  }
}
.share-desk-icon {
  color: #fff;
  @media screen and (max-width: 480px) {
    display: none;
  }
}
.share-mobile-icon {
  display: none;
  color: #fff;
  @media screen and (max-width: 480px) {
    display: block;
    padding: 5px;
  }
}
