@import "../../sass/variables";
@import "../../sass/mixins";

.publicPayment {
  .payment-textfield-container {
    display: block !important;
    @media screen and (max-width: 786px) {
      padding: 30px !important;
    }
    .payment-input {
      width: 100% !important;
      div {
        border-radius: 0 !important;
      }
    }
    .flex-input {
      display: flex;
      @media screen and (max-width: 600px) {
        display: block;
      }
      .payment-input-type {
        width: 20%;
        @media screen and (max-width: 600px) {
          width: 100%;
          margin-top: 8px;
        }
      }
    }
  }
}

.payment-container {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  width: 500px;
  padding: 20px;
  border-radius: 5px;
  transform: translate(-50%, -50%);

  .donate-now {
    margin: 10px 0;
    span {
      display: flex;
      flex-direction: column;
      text-align: left;
      align-self: flex-start;
      padding: 0 5px;
      font-size: 14px;
      margin-top: 5px;
    }
  }
}

.add-offline {
  top: 50%;
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

.public-payment-container {
  outline: none;
  text-align: center;
  width: 50%;
  padding: 20px;
  margin: 0 auto;
}

.payment-container,
.public-payment-container {
  background: #fff;
  margin: 0 auto;

  outline: none;
  text-align: center;

  .input-fild-border {
    border: 1px solid #ccc;
    background: #fff;
    padding: 7px 15px;
    color: #616161;
    :before {
      border-bottom: none;
    }
  }

  .pay-headername {
    h2 {
      color: var(--tal_primary) ;
      font-size: 20px;
      font-weight: bold;
      margin: 8px 0;
      text-transform: uppercase;
      .close-payment {
        position: absolute;
        top: 5px;
        right: 10px;
      }
    }
    .avatar-cont {
      .avatar {
        margin: 0 auto;
        width: 75px;
        height: 60px;
        img {
          width: 150px;
        }
      }
    }
    .payment-text {
      font: 500 16px $font-regular;
      line-height: 1.8em;
      margin: 8px 0;
    }
  }

  .payment-textfield-container {
    display: flex;
    // @media screen and (max-width: 600px) {
    //   display: block;
    // }

    margin: 6px 0;
    .currency {
      margin: 0 !important;
      height: 46px;
      margin-left: 2px !important;
      border: 1px solid #ccc;
      @media screen and (max-width: 600px) {
        margin-left: 0 !important;
      }
      div {
        border-left: none;
      }
      &:before {
        border-bottom: none;
      }
    }
    .payment-input {
      @include input-fields;
      width: 100%;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .inr {
      width: 25%;
      @media screen and (max-width: 600px) {
        width: 100%;
        margin:0 10px;
      }
    }
    .payment-currency {
      &:before {
        border: none;
      }
      &:after {
        border: none;
      }
      &:hover {
        border: none;
        &:before {
          border: none;
        }
      }
      select {
        width: 55px;
        background: #f5f5f5;
        padding: 10px;
      }
    }
  }
  .payment-btn {
    .pay-btn {
   background-color: var(--tal_primary);
      color: #fff;
      text-transform: uppercase;
      // width: 100%;
      font-size: 14px;
      padding: 8px 44px;
    }
  }
}

@media screen and (max-width: 786px) {
  .public-payment-container {
    width: 90% !important;
  }
}

@media screen and (max-width: 600px) {
  .public-payment-container {
    padding: 20px !important;
  }

  .payment-modal {
    div {
      width: 100%;
    }
    .payment-container {
      width: 90%;
      padding: 20px;
    }
  }
}
